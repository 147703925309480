interface Props {
  className?: string
}

export function PixIcon({ className }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <g fill="currentColor" fillRule="evenodd" clipRule="evenodd">
        <path d="m8.114 10.71 1.804 1.805c.169.17.356.32.558.446l-1.583 1.582a1.228 1.228 0 0 1-.874.362c-.33 0-.64-.128-.873-.362l-1.51-1.508c.242-.14.466-.311.664-.51l1.814-1.814m0-1.291a.426.426 0 0 0-.295.118l-2.253 2.253c-.41.412-.967.644-1.548.644H3.947a.156.156 0 0 0-.11.266l2.575 2.576a2.267 2.267 0 0 0 1.607.666c.582 0 1.164-.222 1.608-.666l2.851-2.85h-.276a2.185 2.185 0 0 1-1.549-.644L8.41 9.538a.426.426 0 0 0-.295-.118Zm4.364 3.006h1-1ZM8.02 1.093c.33 0 .64.129.873.362l1.582 1.582c-.2.127-.387.276-.555.445L8.114 5.288 6.302 3.476a3.203 3.203 0 0 0-.665-.512l1.507-1.507.002-.002c.233-.233.544-.362.874-.362m0-1.037c-.582 0-1.164.221-1.608.665l-2.53 2.53a.183.183 0 0 0 .13.313h.006c.581 0 1.138.232 1.548.644L7.82 6.46a.417.417 0 0 0 .59 0l2.244-2.245c.41-.412.967-.644 1.549-.644h.276L9.628.722A2.266 2.266 0 0 0 8.02.056Z" />
        <path d="M3.02 11.85a.546.546 0 0 1-.401-.183c-.02-.015-1.788-1.662-1.788-1.662A2.774 2.774 0 0 1 0 8.018c-.001-.746.29-1.447.817-1.974C.822 6.04 2.6 4.384 2.62 4.368a.538.538 0 0 1 .418-.182h.954c.55.001 1.065.215 1.453.601L7.7 7.041a.56.56 0 0 0 .79 0l2.244-2.245c.39-.387.905-.6 1.453-.602h.785a.57.57 0 0 1 .4.171.512.512 0 0 1 .076.06l1.714 1.598a2.795 2.795 0 0 1 .009 3.982l-1.723 1.607a.516.516 0 0 1-.078.06.557.557 0 0 1-.398.17h-.784a2.055 2.055 0 0 1-1.453-.601L8.495 9a.584.584 0 0 0-.8 0l-2.248 2.247c-.389.388-.905.601-1.453.603H3.02Zm-.025-1.257c.153.142.354.22.563.22h.435c.271 0 .528-.107.722-.3l2.251-2.251.009-.008c.096-.092.2-.17.31-.233a1.592 1.592 0 0 1-.318-.246L4.714 5.522a1.022 1.022 0 0 0-.722-.299h-.514a.653.653 0 0 0-.446.175L1.546 6.784c-.328.33-.508.768-.508 1.234 0 .466.18.904.508 1.235 0 0 .982.91 1.449 1.34Zm5.91-2.572c.11.063.214.141.31.233l.009.008 2.244 2.244c.192.192.45.298.722.299h.072c.337 0 .662-.127.91-.357l1.284-1.195a1.757 1.757 0 0 0 .002-2.467L13.17 5.588a1.335 1.335 0 0 0-.909-.357h-.072c-.27.001-.528.108-.721.3L9.224 7.775a1.59 1.59 0 0 1-.319.246Z" />
      </g>
    </svg>
  )
}
