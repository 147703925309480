import React from "react";

import {
  Container,
  WrapperContent,
  Subtitle,
  Title,
  Text,
  WrapperButton,
  SubtitleWrapper,
  Line,
} from "./styles";

import ButtonOpenAccount from "../LPbutton";

const SectionStart: React.FC = () => {
  return (
    <Container id="start">
      <WrapperContent onScroll={(e) => console.log("scrolling!", e.target)}>
        <SubtitleWrapper>
          <Line />
          <Subtitle>CONTA DIGITAL PJ</Subtitle>
        </SubtitleWrapper>
        <Title>
          Conta Digital <strong>PJ completa</strong>, <strong>gratuita</strong>{" "}
          e <strong>sem burocracias</strong>.
        </Title>
        <Text>
          Conta digital para donos de negócios e empreendedores como você. Sem
          burocracia e sem papelada.
        </Text>
        <WrapperButton>
          <ButtonOpenAccount />
        </WrapperButton>
      </WrapperContent>
    </Container>
  );
};

export default SectionStart;
