import React from "react";
import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Charge } from "../pages/charge";
import { Dashboard } from "../pages/dashboard";
import { Deposit } from "../pages/deposit";
import { Layout } from "../pages/layout";
import { PayBills } from "../pages/pay-bills/index";
import { PayBillsPayInvoice } from "../pages/pay-bills/pay-bills-pay-invoice";
import { PixMyKeys } from "../pages/pixMyKeys";
import { PixNewKey } from "../pages/pixNewKey";
import { PixOptions } from "../pages/pixOptions";
import { Receipt } from "../pages/receipt";
import { Tax } from "../pages/tax";
import { TransferSubscribe } from "../pages/transferSubscribe";
import { TransferSubscribeContact } from "../pages/transferSubscribe/contact";
import Home from "../pages/Home";
import Form from "../pages/createAccountForm";
import Requirements from "../pages/createAccountRequirements";
import CreateAccountType from "../pages/createAccountType";
import { DepositInfoTedDoc } from "../pages/depositInfoTedDoc";
import { DepositInvoicesList } from "../pages/depositInvoicesList";
import { DepositNewInvoice } from "../pages/deposit/deposit-new-invoice";
import { DepositNewInvoiceConfirm } from "../pages/depositNewInvoiceConfirm";
import { DepositPixMyKeys } from "../pages/depositPixMyKeys";
import { FillOutToken } from "../pages/fillOutToken";
import { PayBillsInfoInvoice } from "../pages/pay-bills/pay-bills-pay-invoice-info";
import { PayBillsSchedulePayments } from "../pages/payBillsSchedulePayments";
import { PayBillsSubscribe } from "../pages/payBillsSubscribe";
import { PixConfirm } from "../pages/pix-confirm";
import { PixList } from "../pages/pixList";
import { PixTransfer } from "../pages/pixTransfer";
import { SignIn } from "../pages/signIn";
import { Transfer } from "../pages/transfer";
import { TransferConfirm } from "../pages/transferConfirm";
import { TransferList } from "../pages/transferList";

import { useAuth } from "../hooks/auth";
import { DepositPix } from "../pages/deposit-pix";
import { Forgot } from "../pages/forgot";
import { MyAccount } from "../pages/myAccount";
import { SignoutPage } from "../pages/signout";
import { MultipleCompanies } from "../pages/multiple-companies";
import { TransferSubscribeContactPix } from "../pages/pixTransfer/contact";
import { ListApproved } from "../pages/list-approved";

function RequireAuth({ children }: { children: JSX.Element }) {
  let { account } = useAuth();
  let location = useLocation();

  if (!account) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  return children;
}


const routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="home" element={<Home />} />
        <Route path="type-create-account" element={<CreateAccountType />} />
        <Route path="requirements-create-account" element={<Requirements />} />
        <Route path="create-account" element={<Form />} />

        {/* <Route path="signin" element={<MultipleCompanies />} /> */}
        <Route path="signin" element={<SignIn />} />

        <Route path="token" element={<FillOutToken />} />
        <Route path="forgot" element={< Forgot />} />

        <Route path="/" element={
          <RequireAuth>
            <Layout />
          </RequireAuth>
        }>


          <Route index element={<Dashboard />} />
          <Route path="tax" element={<Tax />} />
          <Route path="myaccount" element={<MyAccount />} />
          <Route path="signout" element={<SignoutPage />} />
          {/* <Route path="extract" element={<Extract />} /> */}
          <Route path="extract" element={<Receipt />} />
          <Route path="deposit">
            <Route index element={<Deposit />} />
            <Route path="deposit-pix" element={<DepositPix />} />
            <Route path="tedordoc" element={<DepositInfoTedDoc />} />
            <Route path="invoices-list" element={<DepositInvoicesList />} />
            <Route path="pixmykeys" element={<DepositPixMyKeys />} />

            <Route path="invoice">
              <Route index element={<DepositNewInvoice />} />
              <Route path="confirm" element={<DepositNewInvoiceConfirm />} />
            </Route>
          </Route>

          <Route path="charge" element={<Charge />} />

          <Route path="list-approved" element={<ListApproved />} />

          <Route path="transfer">
            <Route index element={<Transfer />} />
            <Route path="list" element={<TransferList />} />
            <Route path="subscribe" element={<TransferSubscribe />} />
            <Route path="subscribe-contacts" element={<TransferSubscribeContact />} />
            <Route path="confirm" element={<TransferConfirm />} />
          </Route>

          <Route path="/pix">
            <Route index element={<PixOptions />} />
            <Route path="list" element={<PixList />} />
            <Route path="mykeys" element={<PixMyKeys />} />
            <Route path="mykeys/new" element={<PixNewKey />} />
            <Route path="transfer">
              <Route index element={<PixTransfer />} />
              <Route path="transfer-contacts" element={<TransferSubscribeContactPix />} />
              <Route path="confirm" element={<PixConfirm />} />
            </Route>
          </Route>

          <Route path="/paybills">
            <Route index element={<PayBills />} />
            <Route path="invoice" element={<PayBillsPayInvoice />} />
            <Route path="info-invoice" element={<PayBillsInfoInvoice />} />
            <Route path="subscribe" element={<PayBillsSubscribe />} />
            <Route
              path="schedule-payments"
              element={<PayBillsSchedulePayments />}
            />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default routes;
