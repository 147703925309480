import React, { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import Companies from "./companies";
import Individuals from "./individuals";


const Form: React.FC = () => {
  const location = useLocation();

  const [type, setType] = useState(0);
  const navigation = useNavigate();

  useEffect(() => {
    if (!location.search || location.search === null || location.search === '') {
      navigation('/signin');
    }

    if (location.search === '?type=pf') {
      setType(1);
    } else {
      setType(2);
    }
  }, [location.search, navigation]);


  return (
    <>
      {type === 1 && <Individuals />}
      {type === 2 && <Companies />}
    </>
  );
};

export default Form;
