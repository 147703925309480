import { createGlobalStyle } from "styled-components";
import setup from '../setup/index';

const client = setup.find(cl => window.location.host.includes(cl.host)) || setup[0];


export const GlobalStyle = createGlobalStyle`
    :root {
        --gray-light-line: ${client.colors['gray-light-line']};
        --gray-light-text: ${client.colors['gray-light-text']};
        --gray-light-order: ${client.colors['gray-light-order']};
        --cian-dark: ${client.colors['cian-dark']};
        --gray: ${client.colors['gray']};
        --gray-light-05: ${client.colors['gray-light-05']};
        --gray-background: ${client.colors['gray-background']};
        --background: ${client.colors.background};
        --gray-dark: ${client.colors['gray-dark']};
        --cian: ${client.colors['cian']};
        --white: ${client.colors['white']};
        --white-light: ${client.colors['white-light']};
        --white-light-5: ${client.colors['white-light-5']};
        --green: ${client.colors['green']};
        --red: ${client.colors['red']};
        --line: ${client.colors['line']};
        --modalTitle: ${client.colors['modalTitle']};
        --yellow: ${client.colors['yellow']};
        --confirmBackground: ${client.colors['confirmBackground']};
        --borderInput: ${client.colors['borderInput']};
        --lineBorder: ${client.colors['lineBorder']};
        --box: ${client.colors['box']};
        --boxText: ${client.colors['boxText']};

        --light: 'SoleilLt';
        --semiBold: 'SoleilSb';
        --bold: 'Soleil Bold';
        --regular: 'Soleil';

        --green-lp: #76B350;

        --gray-1-lp: #8B8B8B;
        --gray-2-lp: #F6F6F6;
        --gray-3-lp: #E0E0E0;

        --light: 300;
        --regular: 400;
        --bold: 700;
        --extraBold: 800;
    }

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }


    body {
        background-color: var(--background);
        font-family: 'Soleil';
        min-height: 100vh;
        -webkit-font-smoothing: antialiased;
    }

    .react-datepicker-wrapper {
      width: 100px !important;
    }

    .react-datepicker__input-container {
      width: 100px;
      input {
        width: 100px !important;
      }
    }



`
