import {  useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "../../components/button";
import { useNotification } from "../../hooks/notification";
import { Input } from "./components/input";
import { ReturnBack } from "../../components/return-back";
// import { isBefore } from "date-fns";
// import { handleTicketDeadLine } from "../../utils/handle-ticket-dead-line";

export function PayBillsPayInvoice() {
  const { setOpenNotification } = useNotification();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false)
  const [barcode, setBarcode] = useState('')

  function handleSubmit() {
    if (barcode === '') {
      setOpenNotification({
        type: 'error',
        title: 'Linha digitável inválida',
        notification: 'A linha digitável é obrigatória e não pode ser vazia'
      });

      return;
    }

    if (/\D/.test(barcode)) {
      setOpenNotification({
        type: 'error',
        title: 'Linha digitável inválida',
        notification: 'A linha digitável não pode conter letras'
      });

      return;
    }

    if (barcode.length < 44 || barcode.length > 47) {
      setOpenNotification({
        type: 'error',
        title: 'Tamanho inválido',
        notification: 'A linha digitável pode ter entra 44 e 47 caracteres'
      });

      return;
    }

    // const { dueDate } = handleTicketDeadLine(barcode)
    // const isValidTicket = isBefore(new Date(dueDate), new Date())

    // if (!!isValidTicket) {
    //   setOpenNotification({
    //     type: 'error',
    //     title: 'Boleto vencido',
    //     notification: 'Não é permitido realizar o pagamento de boletos vencidos'
    //   });

    //   return;
    // }

    navigate("/paybills/info-invoice", {
      state: { barcode }
    });

  }


  return (
    <div className="p-8">
      <ReturnBack title="Realizar pagamento de boleto" />
      <form
        onSubmit={handleSubmit}
        className="inline-flex w-full flex-col rounded bg-white p-8 md:w-3/4"
      >
        <h1 className="text-xl">Realizar pagamento de boleto</h1>
        <span className="mb-6 mt-2 text-tx-primary">
          Insira os dados do boleto
        </span>
        <div>
          <Input
            full
            label="Código de barras"
            id="barcode"
            onChange={(input) => setBarcode(input.target.value)}
            value={barcode}
          />
        </div>

        <div className="max-w-[228px] self-end w-full">
          <Button type="button" isLoading={loading} handleFunction={handleSubmit} title="Continuar" />
        </div>
      </form>
      <p className="mt-6 text-base text-gray-500">
        O boleto será liquidado em até 2 dias úteis.
      </p>
    </div>
  );
};
