import React, { forwardRef, ForwardRefRenderFunction, InputHTMLAttributes } from "react";
import IntlCurrencyInput from "react-intl-currency-input";
import styled from "styled-components";

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

interface IInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>  {
  onChange: (e: any, number: number, masked: string) => void;
}



const InputBase: ForwardRefRenderFunction<HTMLInputElement, IInputProps>
  = ({onChange, ...rest}, ref) => {
  return (
    <Container>
    <p className="text-[20px] text-[#505050] font-[var(--regular)] mr-5">Valor:</p>
    <IntlCurrencyInput
      currency="BRL"
      config={currencyConfig}
      ref={ref}
      {...rest}
      onChange={onChange}
    />

  </Container>
  );
}

export const InputInsertValue = forwardRef(InputBase);

export default InputInsertValue;

const Container = styled.div`
  background-color: var(--white);
  display: flex;
  padding: 12px;
  border-radius: 8px;
  align-items: center;
  width: 100%;

  > input {
    flex: 1;
    font-size: 20px;
    color: var(--gray-dark);
    font-family: var(--semiBold);
    border-style: none;
    outline: none;
  }
`;

