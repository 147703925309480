import { FormEvent, Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TextInput from "../../components/TextInput";
import { Button } from "../../components/button";
import InputInsertValue from "../../components/input-value";
import { PasswordInput } from "../../components/passwordInput";
import { ReturnBack } from "../../components/return-back";
import { format } from "date-fns";
import { handleTicketDeadLine } from "../../utils/handle-ticket-dead-line";
import { handleTicketValue } from "../../utils/handle-ticket-value";
import { Dialog, Transition } from "@headlessui/react";
import { api, parseError } from "../../services/api";
import { useAuth } from "../../hooks/auth";
import { CheckIcon, ExclamationIcon } from "@heroicons/react/outline";
import { documentFormatted } from "../../utils/documentFormatted";

interface PaymentResponse {
  success: boolean;
}

interface StateProps {
  barcode: string;
}

export function PayBillsInfoInvoice() {
  const navigate = useNavigate();
  const location = useLocation()
  const { account } = useAuth();


  const [open, setOpen] = useState(false)
  const [pin, setPin] = useState('')
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false)

  const [maskedValue, setMaskedValue] = useState('');
  const [dueAt] = useState(format(new Date(), 'yyyy-MM-dd'));

  const { barcode } = location.state as StateProps;
  const { dueDate } = handleTicketDeadLine(barcode)
  const { valueInReais } = handleTicketValue(barcode)



  const handleCancel = () => {
    navigate("/paybills");
  };

  const handleConfirmButton = async () => {
    setLoading(true)
    try {
      const { data } = await api.post<PaymentResponse>('/payments/pay', {
        barcode,
        amount: valueInReais,
        pin
      })

      if (data.success) {
        setOpen(true)
      }
    } catch (err) {
      const error = parseError(err);
      setError(error.friend);
    } finally {
      setLoading(false)
    }
  };

  const handleChange = (event: FormEvent, value: number, maskedValue: string) => {
    event.preventDefault();
    setMaskedValue(maskedValue);
  };


  function onCloseModal() {
    setOpen(false);
    navigate("/extract");
  }

  function formatDates(originalDate: string) {
    let parts = originalDate.split("-")
    let formattedDate = parts[2] + "/" + parts[1] + "/" + parts[0];
    return formattedDate
  }

  useEffect(() => {
    if (barcode === null) {
      navigate("/paybills");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div className="min-h-screen p-8 bg-[var(--background)]">
      <ReturnBack title="Confirme os dados do pagamento" />

      <div className="bg-white max-w-[480px] rounded-t shadow">
        <div className="max-w-[480px] bg-[var(--white)] p-8 pb-0 flex flex-col">
          <p className="font-[var(--semiBold)] text-[var(--gray-dark)] text-xl mb-2">Código de Barras:</p>
          <span className="text-[17px] font-semibold text-[var(--modalTitle)] mb-5">{barcode}</span>
          <div className="w-full flex gap-5 mb-5">
            <div className="flex-1 mt-4">
              <span className="font-[var(--semiBold)] text-[var(--gray-dark)]">Vencimento</span>
              <TextInput borderColor="#E3E3E3" placeholder="02/03/2022" type="date" value={dueDate} disabled />
            </div>
            <div className="flex-1 mt-4">
              <span className="font-[var(--semiBold)] text-[var(--gray-dark)]">Pagamento</span>
              <TextInput borderColor="#E3E3E3" placeholder="02/03/2022" type="date" value={dueAt} disabled />
            </div>
          </div>
          <InputInsertValue onChange={(event, value, masked) => handleChange(event, value, masked)} value={valueInReais} disabled className="bg-white" />
        </div>
        <div className="max-w-[480px] bg-white p-8 rounded-b border-t border-gray-200">
          <p className="font-[var(--semiBold)] text-[var(--gray-dark)] mb-4">Senha</p>
          <PasswordInput borderRadius="4px" borderColor="#E3E3E3" placeholder="****" onChange={(event) => setPin(event.target.value)} />
        </div>
      </div>

      <div className="flex items-center gap-6 mt-6 max-w-[480px]">
        <Button handleFunction={handleCancel} color="#e3e3e3" title="Cancelar" />
        <Button handleFunction={handleConfirmButton} title="Realizar pagamento" isLoading={loading} />
      </div>

      {!!error && (
        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-5 w-[480px]">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <p className="text-sm text-yellow-700">
                {error}
              </p>
            </div>
          </div>
        </div>
      )}

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onCloseModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                  <div>
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                      <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        Transferência Pix realizada
                        <br />

                        {maskedValue}
                      </Dialog.Title>
                      <div className="mt-2 text-left">
                        <p className="text-sm text-gray-500">
                          <h2 className="mb-5 mt-5 text-lg">Dados da conta debitada</h2>
                          <div>
                            <h2>Nome</h2>
                            <h3>{account.name}</h3>
                          </div>

                          <div className="mt-5">
                            <h2>Documento</h2>
                            <h3>{documentFormatted(account.document)}</h3>
                          </div>

                          <div className="mt-5 flex">
                            <div>
                              <h2>Agência</h2>
                              <h3>{account.branch}</h3></div>
                            <div className="ml-28">
                              <h2>Conta</h2>
                              <h3>{account.account}-{account.accountDigit}</h3></div>
                          </div>

                          <h2 className="mb-5 mt-5 text-lg">Dados do favorecido</h2>

                          <div className="mr-20">
                            <h2>Códico de barras</h2>
                            <h3>{barcode}</h3>
                          </div>

                          <div className="mt-5 flex">
                            <div className="mr-20">
                              <h2>Data de vencimento:</h2>
                              <h3>{formatDates(dueDate)}</h3>
                            </div>

                            <div>
                              <h2>Data de pagamento:</h2>
                              <h3>{formatDates(dueAt)}</h3>
                            </div>
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                      onClick={() => onCloseModal()}
                    >
                      Ir para o extrato
                    </button>

                    <button
                      type="button"
                      className=" mt-4 inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                    >
                      Comprovante disponível no extrato
                    </button>

                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};
