/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useRef, useState } from "react";
import {
  AccountStats,
  CardAccountInfo,
  Container,
  Content,
  Items,
  Transactions
} from "./styles";

import { ChargeIcon } from "../../components/icons/charge-icon";
import { TransferIcon } from "../../components/icons/transfer-icon";



import { Card } from "../../components/card";
import { Contacts } from "../../components/contacts";
import { Orders } from "../../components/orders";
import { Projection } from "../../components/projection";
import { useAuth } from "../../hooks/auth";
import { currencyFormat } from "../../utils/currencyFormat";
import { documentFormatted } from "../../utils/documentFormatted";

import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/outline';
import { Link } from "react-router-dom";
import TextInput from "../../components/TextInput";
import { useNotification } from "../../hooks/notification";
import { api } from "../../services/api";
import { PixIcon } from "../../components/icons/pix-icon";
import { useBalance } from "../../hooks/balance";

export function Dashboard() {
  const { account, refreshAccount } = useAuth();
  const { setOpenNotification } = useNotification();
  const { isBalanceVisible } = useBalance()

  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);

  const [pin, setPin] = useState('');

  useEffect(() => {
    if (!account.pin) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [account]);

  useEffect(() => {
    refreshAccount();
  }, []);


  async function handlePin() {
    try {
      await api.post("accounts/pin", {
        pin,
      });
      setOpen(false);
      setOpenNotification({
        type: 'success',
        title: "Cadastro realizado",
        notification: "Senha de 4 dígitos cadastrada com sucesso"
      })
    } catch (err) {
      setOpenNotification({
        type: 'error',
        title: "Erro!",
        notification: "Apenas números"
      })
    }
  }

  return (
    <>
      <Container>
        <Content>
          <AccountStats>
            <Card title="Saldo na conta" value={isBalanceVisible ? currencyFormat(account.balance) : '***'} color={"#e2af0d"} />
            <Card
              title="Rentabilidade do Mês"
              value={isBalanceVisible ? "R$ 0,00" : '***'}
              color={"#49D294"}
            />
            <Card
              title="Boletos em aberto"
              value={isBalanceVisible ? "R$ 0,00" : '***'}
              color={"#fff"}
              textColor={"#142249"}
            />
            <CardAccountInfo>
              <div>
                <p className="name">{account.name}</p>
                <p className="id">{documentFormatted(account.document)}</p>
                {!account.isPool && (
                  <>
                    <p className="bankCode">
                      Banco: <strong>{account.bank}</strong>
                    </p>
                    <p className="agency">
                      Agência: <strong>{account.branch}</strong>
                    </p>
                  </>
                )}
                <p className="account">
                  Conta: <strong>{account.account}-{account.accountDigit}</strong>
                </p>
              </div>
            </CardAccountInfo>
          </AccountStats>

          <Transactions>
            <Orders />
            <Projection />
            <Contacts />
            <Items>
              <Link
                to="charge"
                className="relative flex h-full w-full cursor-pointer flex-col items-center justify-center rounded bg-white shadow after:absolute after:top-0 after:h-1 after:w-4 after:bg-primary after:content-[''] hover:bg-green-100/60"
              >
                <div className="flex flex-col items-center">
                  <div className="mb-5 text-base font-semibold text-primary">
                    Cobrar
                  </div>
                  <ChargeIcon className="h-20 w-20 text-primary" />
                </div>
              </Link>

              <Link
                to="/transfer"
                className="relative flex h-full cursor-pointer flex-col items-center justify-center rounded bg-white after:absolute after:top-0 after:h-1 after:w-4 after:bg-primary after:content-[''] hover:bg-green-100/60"
              >
                <div className="flex flex-col items-center">
                  <div className="mb-5 text-base font-semibold text-primary">
                    Transferir
                  </div>
                  <TransferIcon className="h-20 w-20 text-primary" />
                </div>
              </Link>

              <Link
                to="/pix"
                className="relative flex h-full cursor-pointer flex-col items-center justify-center rounded bg-white after:absolute after:top-0 after:h-1 after:w-4 after:bg-primary after:content-[''] hover:bg-green-100/60"
              >
                <div className="flex flex-col items-center">
                  <div className="mb-5 text-base font-semibold text-primary">
                    Pix
                  </div>
                  <PixIcon className="h-20 w-20 text-primary" />
                </div>
              </Link>



            </Items>
          </Transactions>
        </Content>
      </Container>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => { }}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                  <div>
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100">
                      <ExclamationIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        Senha de 4 dígitos
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-md text-gray-500">
                          Você ainda não cadastrou uma senha de 4 dígitos
                        </p>
                        <p className="text-md text-gray-500">
                          Para continuar cadastre uma senha de 4 dígitos
                        </p>
                        <p className="mt-5 flex justify-center">
                          <div className="w-48 ">
                            <TextInput
                              placeholder="Senha de 4 dígitos"
                              maxLength={4}
                              value={pin}
                              type="password"
                              onChange={input => setPin(input.target.value)}
                            />
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 flex justify-center">
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                      onClick={handlePin}
                    >
                      Cadastrar
                    </button>
                    {/* <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpen(true)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button> */}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

