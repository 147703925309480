import styled from 'styled-components';

import { ReactComponent as BillIconSVG } from '../../assets/billSide.svg';
import { CardActionOption } from "../../components/cardActionOption";

export function PayBills() {
  return (
    <div className='bg-[var(--background)]' style={{ minHeight: 'calc(100vh - 80px)' }}>
      <main className='flex flex-col' style={{ gridArea: 'content' }}>
        <div className='w-full p-8 flex flex-wrap justify-center gap-8 md:justify-start'>
          <CardActionOption
            linkTo="invoice"
            Icon={BillIcon}
            title={`Pagar Contas`}
          />
        </div>
      </main>
    </div>
  );
};

const BillIcon = styled(BillIconSVG)`
  fill: var(--cian);
  width: 16px;
`
