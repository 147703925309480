type Props = {
  className?: string
}

export function DepositIcon({ className }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 1 16 18"
      fill="none"
      className={className}
    >
      <g fill="currentColor" fillRule="evenodd" clipRule="evenodd">
        <path d="M15.422 7.238H14.1V5.034a.44.44 0 0 0-.441-.44h-1.763a.44.44 0 0 0 0 .88h1.322v1.763H1.762a.881.881 0 1 1 0-1.762h2.204a.44.44 0 1 0 0-.881H1.761C.79 4.594.001 5.384 0 6.356v9.694c.001.973.79 1.761 1.762 1.762h13.66a.44.44 0 0 0 .44-.44V7.678a.44.44 0 0 0-.44-.44ZM1.761 16.93a.882.882 0 0 1-.88-.881V7.874c.266.158.57.243.88.245h13.219v2.643h-3.085a.44.44 0 0 0-.44.441v2.644c0 .243.197.44.44.44h3.085v2.644H1.762Zm13.219-3.525h-2.644v-1.762h2.644v1.762Z" />
        <path d="M7.656 6.26c.16.128.39.128.55 0l2.203-1.763a.44.44 0 1 0-.55-.688l-1.487 1.19V.63a.44.44 0 1 0-.882 0v4.37l-1.487-1.19a.44.44 0 1 0-.55.688L7.656 6.26Z" />
      </g>
    </svg>
  )
}
