import React, { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../components/loading";
import { useAuth } from "../../hooks/auth";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import { ExclamationIcon } from '@heroicons/react/solid';
// force deploy

import {
  ButtonEyeVisibility,
  ButtonSignIn,
  Container,
  Content,
  EyeDisabledIcon,
  EyeEnabledIcon,
  ForgetPassword,
  Input,
  InputPassword,
  Line,
  NotHaveAnAccount,
  Reference,
  Separator,
  // StricLogo,
  Title,
  WrapperBackground,
  WrapperContent,
  WrapperForm,
  WrapperLogo,
  Error,
} from "./styles";
import { parseError } from "../../services/api";
import { useClient } from "../../hooks/client";


export function SignIn() {
  const { client } = useClient();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const navigation = useNavigate();
  const { signIn, account } = useAuth();

  const [document, setDocument] = useState('');
  const [password, setPassword] = useState('');

  const handlePasswordVisibility = () => {
    setIsPasswordVisible((state) => !state);
  };

  useEffect(() => {
    if (account) {
      navigation("/");
    }
  });

  const handleSignIn = async (event: FormEvent) => {
    event.preventDefault();

    if (!document || !password) {
      return;
    }

    try {
      setLoading(true);
      setError('');
      await signIn({
        document: document.replace(/[/,.,-\s]/g, ""),
        password,
      });

      setLoading(false);
      navigation("/");
    } catch (err) {
      const error = parseError(err);
      setLoading(false);
      setError('');
      setError(error.friend);
    }
  };

  return (
    <Container>
      <WrapperBackground />
      <WrapperContent>
        <Content>
          <WrapperLogo>
            <img src={client.whiteLogo.image} alt={client.name} style={{
              width: client.whiteLogo.maxWidth,
              height: client.whiteLogo.maxHeight,
              }} />
            {/* <StricLogo /> */}
          </WrapperLogo>
          <Title>
            Bem-vindo ao internet <br />
            <b>banking da {client.name}</b>
          </Title>
          <Separator />
          <Reference>
            Preencha os campos abaixo para acessar sua conta
          </Reference>
          <WrapperForm onSubmit={handleSignIn}>
            <Input>
              <span>CPF/CNPJ:</span>
              <CpfCnpj
                placeholder="Digite seu CPF/CNPJ" type="text"
                onChange={(input: any) => setDocument(input.target.value)}
                value={document}
              />
            </Input>
            <InputPassword>
              <div>
                <span>Senha:</span>
                <input
                  onChange={input => setPassword(input.target.value)}
                  value={password}
                  placeholder="Digite sua senha"
                  type={isPasswordVisible ? "text" : "password"}
                />
              </div>
              <ButtonEyeVisibility onClick={handlePasswordVisibility} type="button">
                {isPasswordVisible ? <EyeEnabledIcon /> : <EyeDisabledIcon />}
              </ButtonEyeVisibility>
            </InputPassword>
            {!!error && (
              <Error>
                <div className="bg-red-50 border-l-4 border-red-400 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm text-red-700">
                        {error}
                      </p>
                    </div>
                  </div>
                </div>
              </Error>
            )}
            <ButtonSignIn type="submit">
              <Loading isLoading={loading} />
              Entrar
            </ButtonSignIn>
          </WrapperForm>
          <ForgetPassword href="/forgot">Esqueci minha senha</ForgetPassword>
          <Line />
          <NotHaveAnAccount to="/type-create-account">
            Não tem uma conta? Abrir conta {client.name}
          </NotHaveAnAccount>
        </Content>
      </WrapperContent>
    </Container>
  );
};
