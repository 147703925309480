import { useEffect, useState } from "react";
import {
  Container,
  Header,
  OrdersWrapper,
  Order,
  IconWrapper,
  // Icon,
  InfoOrder,
  Balance,
  OrderDetails,
  BalanceDetails,
  Type,
  Name,
  // ArrowIcon
} from "./styles";

import { ChargeIcon } from "../icons/charge-icon";
import { DepositIcon } from "../icons/deposit-icon";
import { api } from "../../services/api";
// import { startOfMonth, endOfMonth } from "date-fns";
import { normalizeName } from "../../utils/normelizaName";
import { currencyFormat } from "../../utils/currencyFormat";
import { format, startOfYear, endOfYear } from "date-fns";
import brasilLocale from 'date-fns/locale/pt-BR';

import { Link } from "react-router-dom";
import { Skeleton } from "../skeleton";

interface IItems {
  id: string;
  description: string;
  balanceAfter: number;
  date: string;
  status: string;
  type: string;
  credit: boolean;
  amount: number;
}

export const Orders = () => {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<IItems[]>([]);
  useEffect(() => {
    const start = startOfYear(new Date());
    const end = endOfYear(new Date());

    api.get(`/transactions?page=0&start=${start}&end=${end}`).then(({ data }) => {
      setItems(data.items.slice(0, 5));
      setLoading(false);
    });
  }, []);

  function getType(type: string): string {
    if (type === 'PIX') {
      return "Pix";
    }

    if (type === 'TED') {
      return "Transferência";
    }

    if (type === 'P2P') {
      return "Transferência";
    }

    if (type === 'BILLET') {
      return "Boleto";
    }

    if (type === 'PAYMENT') {
      return "Pagamento";
    }

    if (type === 'INITIAL') {
      return '';
    }

    return type;
  }

  return (
    <Container>
      <Header>
        <span>Últimos lançamentos</span>
        <Link to="/extract">
          <p>Ver extrato completo</p>
        </Link>

      </Header>
      <OrdersWrapper>

        {!loading && !items.length && (
          <div>
            <div className="text-center">
              <h3 className="mt-2 text-sm font-medium text-gray-900">Nenhum lançamento para o mês de <strong>{format(new Date(), 'LLLL', { locale: brasilLocale })}</strong></h3>
              <p className="mt-1 text-sm text-gray-500">Faça sua primeira transação</p>
              <div className="mt-6">
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Transferir
                </button>
              </div>
            </div>
          </div>
        )}

        <Skeleton isActive={loading} repeat={4} />


        {!loading && items.map(item => (
          <Order key={item.id}>
            <OrderDetails>
              <IconWrapper>
                {item.credit ? (
                  <DepositIcon className="h-5 w-5 text-green-500" />
                ) : (
                  <ChargeIcon className="h-5 w-5 text-red-400/70" />
                )}
              </IconWrapper>
              <InfoOrder>
                <Type>{getType(item.type)}</Type>
                {/* <Type>{item.credit ? 'crédito' : 'débito'}</Type> */}
                <Name className="truncate">{normalizeName(item.description)}</Name>
              </InfoOrder>
            </OrderDetails>
            <BalanceDetails>
              <Balance credit={item.credit}>
                {!item.credit && item.amount > 0 ? '-' : '+'} {currencyFormat(item.amount)}
              </Balance>
              {/* <ArrowIcon credit={item.credit.toString()} /> */}
            </BalanceDetails>
          </Order>
        ))}
      </OrdersWrapper>
    </Container>
  )
}
