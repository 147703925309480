import styled from "styled-components";

// import striclaptopimage from "../../assets/striclaptopimage.png";
import SignInImage from "../../assets/signinimage.png";

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
`;

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 100%;
  display: flex;
`;

export const WrapperLeft = styled.div`
  flex: 1;
  min-height: 100vh;
  display: none;

  @media (min-width: 840px) {
    background-image: url(${SignInImage});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: unset;
  }
`;

export const WrapperRight = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const GoBackButtonWrapper = styled.button`
  font-size: 14px;
  color: var(--gray);
  text-decoration: underline;
  cursor: pointer;
  background-color: transparent;
  border-style: none;
  text-align: start;

  > svg {
    margin-right: 12px;
  }
`;

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 488px;
  flex: 1;
  margin: 0 auto;

  > svg {
    margin-bottom: 63px;
  }
`;

export const Title = styled.span`
  font-size: 42px;
  color: var(--gray);
  font-weight: var(--light);
  max-width: 384px;
  line-height: 52px;
  margin-bottom: 14px;

  > strong {
    font-weight: var(--bold);
  }
`;

export const Text = styled.span`
  font-size: 16px;
  color: var(--gray-1-lp);
  font-family: var(--regular);
  max-width: 384px;
  line-height: 28px;
  display: none;

  @media (min-width: 840px) {
    display: unset;
  }
`;

export const TextRequirement = styled.strong`
  text-transform: uppercase;
  color: var(--gray);
  font-size: 16px;
`;

export const ListWrapper = styled.div`
  margin-bottom: 63px;
  margin-top: 44px;
`;

export const Subtitle = styled.div`
  font-size: 16px;
  font-weight: var(--bold);
  color: var(--white);
  position: relative;
  text-align: center;

  @media (min-width: 1120px) {
    text-align: start;
  }
`;

export const Line = styled.div`
  height: 3px;
  width: 15px;
  background: var(--cian);
  margin: 0px 12px 0px 0px;

  @media (min-width: 1120px) {
    flex-direction: row;
    align-items: center;
  }
`;

export const Requirement = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 0px 14px 0px;
`;

export const WrapperButton = styled.div`
  width: 100%;
  @media (min-width: 840px) {
    max-width: 176px;
  }
`;
