import React, { Fragment, useEffect, useState } from "react";
import { Skeleton } from "../../components/skeleton";
import { api, parseError } from "../../services/api";
import { currencyFormat } from "../../utils/currencyFormat";
import { dateFormat } from "../../utils/dateFormat";

import { Container, Content, Table, TableWrapper } from "./styles";
import { Button } from "../../components/button";
import { documentFormatted } from "../../utils/documentFormatted";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/outline";
import TextInput from "../../components/TextInput";
import { useNotification } from "../../hooks/notification";

interface IItems {
  id: string;
  amount: number;
  status: string;
  created_at: string;
  beneficiary: {
    bank: string;
    name: string;
    branch: string;
    account: string;
    document: string;
    accountDigit: string;
  };
  key?: string;
}

export const ListApproved = () => {
  const { setOpenNotification } = useNotification();

  const [loading, setLoading] = useState(true);
  const [pin, setPin] = useState("");
  const [hasPin, setHasPin] = useState(false);
  const [pinOpen, setPinOpen] = useState(false);
  const [operation, setOperation] = useState("");
  const [id, setId] = useState("");

  const [items, setItems] = useState<IItems[]>([]);
  useEffect(() => {
    setLoading(true);

    api.get(`/transfers/list`).then(({ data }) => {
      setItems(data.transfers);
      setLoading(false);
    });
  }, []);

  function handlePopUpPin(id: string, operation: string) {
    setId(id);
    setOperation(operation);
    setPinOpen(true);
  }

  async function confirmOperation() {
    try {
      setLoading(true);
      if (operation === "approve") {
        await api.put(`/transfers/approve-reprove`, {
          id,
          pin,
          status: "APPROVED",
        });

        setOpenNotification({
          type: "success",
          title: "Operação confirmada",
          notification: "Operação confirmada com sucesso",
        });
      } else {
        await api.put(`/transfers/approve-reprove`, {
          id,
          pin,
          status: "REPROVED",
        });
        setOpenNotification({
          type: "success",
          title: "Operação rejeitada",
          notification: "Operação rejeitada com sucesso",
        });
      }
    } catch (err) {
      const error = parseError(err);

      setOpenNotification({
        type: "error",
        title: "Erro ao confirmar operação",
        notification: error.message
      });
    } finally {
      setPinOpen(false);
      setLoading(false);
      setPin("");
    }
  }

  return (
    <>
      <Container>
        <Content>
          <Table>
            <TableWrapper>
              {!loading && items.length <= 0 && (
                <div className="p-6">
                  <div className="text-center">
                    <h3 className="mt-2 text-sm font-medium text-gray-900">
                      Nenhuma transação pendente de aprovação
                    </h3>
                  </div>
                </div>
              )}
              <Skeleton space={4} isActive={loading} repeat={5} />
              <table>
                {!loading && items.length > 0 && (
                  <thead>
                    <tr>
                      <th>Data</th>
                      <th>Beneficiário</th>
                      <th>CPF/CNPJ</th>
                      <th>Valor</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                )}

                <tbody>
                  {!loading &&
                    items.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td>{dateFormat(item.created_at)}</td>
                          <td>
                            <div className="flex flex-col">
                              <span>{item?.beneficiary?.name}</span>
                              <span>
                                {documentFormatted(item?.beneficiary?.document)}
                              </span>
                            </div>
                          </td>
                          <td>
                            <div className="flex flex-col">
                              {item?.key ? (
                                <>
                                  <span>Pix</span>
                                  <span>Chave: {item?.key}</span>
                                </>
                              ) : (
                                <>
                                  <span>Banco: {item?.beneficiary?.bank}</span>
                                  <span>
                                    Agência: {item?.beneficiary?.branch}
                                  </span>
                                  <span>
                                    Conta: {item?.beneficiary?.account}-
                                    {item?.beneficiary?.accountDigit}
                                  </span>
                                </>
                              )}
                            </div>
                          </td>
                          <td>{currencyFormat(item.amount)}</td>
                          <td>
                            <div className="flex gap-2">
                              <Button
                                title="Aprovar"
                                handleFunction={() =>
                                  handlePopUpPin(item.id, "approve")
                                }
                              />
                              <Button
                                title="Reprovar"
                                color="#E74C3C"
                                handleFunction={() =>
                                  handlePopUpPin(item.id, "reject")
                                }
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </TableWrapper>
          </Table>
        </Content>
      </Container>

      <Transition.Root show={pinOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setPinOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                  <div>
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                      <CheckIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Senha
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="mt-5 flex justify-center">
                          <div className="w-48 ">
                            <TextInput
                              placeholder="Senha de 4 dígitos"
                              maxLength={4}
                              value={pin}
                              type="password"
                              onChange={(input) => setPin(input.target.value)}
                            />
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <Button
                      type="submit"
                      isLoading={loading}
                      title="Continuar"
                      handleFunction={() => {
                        setHasPin(true);
                        confirmOperation();
                      }}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
