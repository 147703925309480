import React, { FormEvent, useEffect, useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/button";
import FormSelectButton from "../../components/FormSelectButton";
// import SelectInput from "../../components/SelectInput";
import { StepHeader } from "../../components/stepHeader";
import TextInput from "../../components/TextInput";
import { InputSelect, SelectObject } from "../../components/select";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Dialog, Transition } from '@headlessui/react'


import { banks } from "../../utils/banks";

import {
  ButtonWrapper,
  Container,
  Content,
  ContentWrapper,
  Form,
  FormTitle,
  FormWrapper,
  SelectButtonWrapper,
  WrapperHeader,
} from "./styles";
import { api } from "../../services/api";
import { ExclamationCircleIcon, XIcon } from "@heroicons/react/outline";

interface Contact {
  id: string
  account: string;
  accountDigit: string;
  bank: string;
  bankName: string;
  branch: string;
  check_payer: string;
  date: string;
  key: string;
  name: string;
  document: string;
  type: string;
  typeTransfer: string;
}


type FormData = {
  document: string;
  name: string;
  agency: string;
  account: string;
  digit: string;
};

const schema = yup.object({
  name: yup.string().required("Nome é obrigatório"),
  document: yup.string().required("Documento é obrigatório"),
  agency: yup.string().required('Agência é obrigatório'),
  account: yup.string().required('Conta é obrigatório'),
  digit: yup.string().required("Dígito é obrigatório.")
}).required();

export const TransferSubscribeContact: React.FC = () => {
  const [buttonSelected, setButtonSelected] = useState("personal");
  const [mask, setMask] = useState("999.999.999-99");
  const [bankSelected, setBankSelected] = useState<SelectObject>(banks[0]);

  const [contacts, setContacts] = useState<Contact[]>([]);
  const [open, setOpen] = useState(false)
  const [idToDelete, setIdToDelete] = useState('')

  const { register, handleSubmit, formState: { errors } } = useForm<FormData>({
    resolver: yupResolver(schema)
  });

  const handlePersonalAccount = () => {
    setButtonSelected("personal");
  };
  const handleBusinnessAccount = () => {
    setButtonSelected("businness");
  };

  // const [selectedAccountType, setSelectedAccountType] =
  //   useState("Tipo de conta");

  // const handleAccountTypeSelect = (option: string) => {
  //   setSelectedAccountType(option);
  // };

  // const [selectedBank, setSelectedBank] = useState("Banco");
  // const handleBankSelect = (option: string) => {
  //   setSelectedBank(option);
  // };


  async function getContacts() {
    try {
      const { data } = await api.post('/transfers', {
        onlyContacts: true,
        contactType: "TED",
        amount: 0.01,
        save: false,
        beneficiary: {
          "name": "mock player",
          "document": "08044735011",
          "bank": "111",
          "bankName": "bank name",
          "branch": "1111",
          "account": "11111",
          "accountDigit": "1"
        }
      })


      setContacts(data.contacts)
    } catch (err) {
      //
    }
  }

  async function transferToContact(contact: Contact) {
    const bankSelectedContact = banks.find((b) => Number(b.id) === Number(contact.bank))

    navigate('/transfer/confirm', {
      state: {
        name: contact.name,
        document: contact.document,
        agency: contact.branch,
        account: contact.account,
        digit: contact.accountDigit,
        bank: bankSelectedContact
      }
    });

    return;
  }

  async function deleteContact(id: string) {
    try {
      setOpen(false);
      await api.post('/transfers', {
        onlyContacts: true,
        contactType: "TED",
        deleteContactId: id,
        amount: 0.01,
        save: false,
        beneficiary: {
          "name": "mock player",
          "document": "08044735011",
          "bank": "111",
          "bankName": "bank name",
          "branch": "1111",
          "account": "11111",
          "accountDigit": "1"
        }
      })

      await getContacts();

    } catch(err) {
      //
    }
  }

  function handleConfirmDeleteContact(id: string) {
    setIdToDelete(id);
    setOpen(true);
  }

  useEffect(() => {
    getContacts();
  }, [])


  console.log(contacts);


  const handleFormSubmit: SubmitHandler<FormData> = async (values, event) => {
    navigate('/transfer/confirm', {
      state: { ...values, bank: bankSelected }
    });

    return;
  }

  const navigate = useNavigate();
  const handleNextScreen = () => {
    navigate('/transfer/confirm');
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (buttonSelected === 'personal') {
      setMask("999.999.999-99");
    } else {
      setMask("99.999.999/9999-99");
    }
  }, [buttonSelected]);

  return (
    <Container>
      <Content>
        <ContentWrapper>
          <WrapperHeader>
            <StepHeader
              handleGoBackFunction={handleGoBack}
              title="Escolhe seus contatos"
            />
          </WrapperHeader>


          <div className="px-4 sm:px-6 lg:px-8">
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y">
                    <thead>
                      <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                          Beneficiário
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Banco
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Conta
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Transferir
                        </th>
                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                          <span className="sr-only">Excluir</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-20">
                      {contacts?.map((contact) => (
                        <tr key={contact.id}>
                          <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                            <div className="flex items-center">
                              <div className="ml-4">
                                <div className="font-medium text-gray-900">{contact.name}</div>
                                <div className="mt-1 text-gray-500">{contact.document}</div>
                              </div>
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                            <div className="text-gray-900">{contact?.bankName}</div>
                            <div className="mt-1 text-gray-500">{contact?.bank}</div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                            <div className="text-gray-900">Agência: {contact?.branch}</div>
                            <div className="mt-1 text-gray-500">Conta: {contact?.account}-{contact?.accountDigit}</div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">

                            <span className="text-indigo-600 hover:text-indigo-900 cursor-pointer" onClick={() => transferToContact(contact)}>
                              Transferir para este beneficiario {'->'}
                            </span>
                          </td>
                          <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                            <span className="text-indigo-600 hover:text-indigo-900 cursor-pointer" onClick={() => handleConfirmDeleteContact(contact.id)}>
                              Excluir<span className="sr-only">, {contact.name}</span>
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>


          {/* <Form onSubmit={handleSubmit(handleFormSubmit)}>


            <FormTitle>Complete os dados</FormTitle>
            <FormWrapper>
            <div className="mb-5">
              <InputSelect
                  label=""
                  selected={bankSelected}
                  options={banks}
                  onChange={setBankSelected}
                  onSearch='Digite um banco para pesquisar'
                />
            </div>

              <div className="mb-5">
                <TextInput
                  placeholder={buttonSelected === 'personal' ? 'CPF' : "CNPJ"}
                  mask={mask}
                  {...register('document')}
                  error={errors.document}
                />
              </div>

              <div className="mb-5">
                <TextInput
                  placeholder="Nome"
                  {...register('name')}
                  error={errors.name}
                />
              </div>

              <div className="mb-5">
                <TextInput placeholder="Agência" mask="9999"
                  {...register('agency')}
                  error={errors.agency}
                />
              </div>

              <div className="mb-5">
              <TextInput
                placeholder="Conta sem dígito (só números)"
                mask="9999999999999999999"
                {...register('account')}
                  error={errors.account}
              />
             </div>

             <div className="mb-5">
                <TextInput
                  placeholder="Dígito (só número)"
                  // mask="9"
                  maxLength={1}
                  {...register('digit')}
                  error={errors.digit}
                />
              </div>

            </FormWrapper>
            <ButtonWrapper>
              <Button
                type="submit"
                title="Continuar"
              />
            </ButtonWrapper>
          </Form> */}
        </ContentWrapper>
      </Content>

      <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationCircleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Excluir Beneficiário
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Tem certeza que deseja excluir o beneficiário?
                        Esta ação é irreversível.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                    onClick={() => deleteContact(idToDelete)}
                  >
                    Excluir beneficiário
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                  >
                    Cancelar
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    </Container>
  );
};
