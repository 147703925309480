import React, { InputHTMLAttributes, forwardRef, ForwardRefRenderFunction } from "react";
import InputMask from 'react-input-mask';
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import { FieldError } from "react-hook-form";
import IntlCurrencyInput from "react-intl-currency-input";


import { Container, Label } from "./styles";
interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: string
  mask?: string;
  error?: FieldError;
  onChanged?: (e: any, number: number, masked: string) => void;
}

interface Props {
  label: string;
  nomargin?: boolean;
}

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

const InputBase: ForwardRefRenderFunction<HTMLInputElement, Props>
  = ({  label, mask, error = null, nomargin = false, onChanged, ...rest}, ref) => {


    return (
      <>
      <Container nomargin={nomargin}>
      <Label>{label}</Label>
      {mask ? (
        <>
          {mask !== 'currency' ? (
            <InputMask maskChar="" mask={mask} inputRef={ref} type="text" {...rest} />
          ) : (
            <IntlCurrencyInput
              currency="BRL"
              config={currencyConfig}
              ref={ref}
              {...rest}
              onChange={onChanged}
          />
          )}
        </>
      ): (
        <input ref={ref} {...rest}/>
      )}

      {!!error && (
              <div>
                {!!error && (
                  <p className="mt-2 text-sm text-red-600" id="email-error">
                    {error.message}
                  </p>
                )}
              </div>
            )}
    </Container>
      </>
    )
  }

export const FormButton = forwardRef(InputBase);

export default FormButton;
