import { createContext, ReactNode, useContext, useEffect, useState } from "react";

import setup from "../setup";

type Logo = {
  image: string;
  width: number;
  height: number;
  maxWidth: number;
  maxHeight: number;
}

type Colors = {
  'gray-light-line': string;
  "gray-light-text": string;
  "gray-light-order": string;
  "cian-dark": string;
  "gray": string;
  "gray-light-05": string;
  "gray-background": string;
  "gray-dark": string;
  "cian": string;
  "white": string;
  "white-light": string;
  "white-light-5": string;
  "green": string;
  "red": string;
  "line": string;
  "modalTitle": string;
  "yellow": string;
  "confirmBackground": string;
  "borderInput": string;
  "lineBorder": string;
  "box": string;
  "boxText": string;
}



type ClientContextData = {
  client: {
    name: string;
    host: string;
    client_id: string;
    colors: Colors;
    whiteLogo: Logo;
    darkLogo: Logo;
  }
};

type AuthProviderProps = {
  children: ReactNode;
}

export const ClientContext = createContext({} as ClientContextData);

export function ClientProvider({ children }: AuthProviderProps) {
  const [client, setClient] = useState<any>(false);
  useEffect(() => {
    const clientConfig = setup.find(cl => window.location.host.includes(cl.host)) || setup[0];
    setClient(clientConfig);

  }, []);

  if (!client) {
    return null;
  }

  return (
    <ClientContext.Provider value={{ client }}>
      {children}
    </ClientContext.Provider>
  )
}

export function useClient() {
  const context = useContext(ClientContext);
  return context;
}




