import styled from "styled-components";
import { ReactComponent as BillIconSVG } from '../../assets/billSide.svg'
// import { ReactComponent as PixIconSVG } from '../../assets/pix.svg'
// import { ReactComponent as transferMoneyIconSVG } from '../../assets/transferSide.svg'
import { CardActionOption } from "../../components/cardActionOption";


export const Deposit = () => {
  return (
    <div className='bg-[var(--background)]' style={{ minHeight: 'calc(100vh - 80px)' }}>
      <main className='flex flex-col' style={{ gridArea: 'content' }}>
        <div className='w-full p-8 flex flex-wrap justify-center gap-8 md:justify-start'>
          {/* <CardActionOption
            linkTo="deposit-pix"
            Icon={PixIcon}
            title={`Depósito por Pix`}
          />
          <CardActionOption
            Icon={transferMoneyIcon}
            title={"Depósito por TED ou DOC"}
            linkTo="tedordoc"
          /> */}
          <CardActionOption
            linkTo="invoice"
            Icon={BillIcon}
            title={"Depósito por Boleto"}
          />
        </div>
      </main>
    </div>
  );
};

const BillIcon = styled(BillIconSVG)``
// const PixIcon = styled(PixIconSVG)``
// const transferMoneyIcon = styled(transferMoneyIconSVG)``
