import React, { FormEvent, useState } from "react";

import { useNavigate } from "react-router-dom";
import { ChevronLeft } from "lucide-react";

import { Button } from "../../components/button";
import TextInput from "../../components/TextInput";
import { api } from "../../services/api";
import { useClient } from "../../hooks/client";

export const TransferList: React.FC = () => {
  const { client } = useClient();
  const navigate = useNavigate();

  const [document, setDocument] = useState('');
  const [loading, setLoading] = useState(false);

  const handleCancelButton = () => {
    navigate("/transfer");
  };

  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      const { data } = await api.post("/utils/accounts/search", {
        document: document.replace(/[/,.,-\s]/g, ""),
      });
      setLoading(false);
      console.log(data);
      navigate('/transfer/confirm', {
        state: {
          name: data.name,
          document: data.document,
          agency: data.branch,
          account: data.account,
          digit: data.accountDigit,
          bank: {
            id: 0,
            name: client.name,
            hidden: client.name,
          }
        }
      });
      return;
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }

  return (
    <div className="h-auto">
      <main className="flex flex-col p-8" style={{ gridArea: 'content', backgroundColor: 'var(--background)' }}>
        <div className="mb-6 flex items-center">
          <div
            onClick={() => navigate(-1)}
            className="mr-4 flex h-10 w-10 cursor-pointer items-center justify-center rounded bg-white shadow-sm hover:opacity-80"
          >
            <ChevronLeft className="h-7 w-7 text-primary" strokeWidth={2.2} />
          </div>
          <h1 className="mt-1 text-2xl text-tx-primary">
            Transferir TED/DOC
          </h1>
        </div>

        <form onSubmit={handleFormSubmit} className="max-w-[488px]">
          <div className="rounded-md shadow bg-white px-6 pt-9 pb-12 flex flex-col">
            <h1 className="text-xl">Nova transferência</h1>
            <p className="mb-6 mt-2 text-tx-primary">
              Informe o CPF/CNPJ do destinatário:
            </p>
            <TextInput
              mask="CPF_CNPJ"
              placeholder={"CPF/CNPJ"}
              onChange={(input: any) => setDocument(input.target.value)}
              value={document}
            />
          </div>

          <div className="flex items-center gap-6 mt-6 max-w-[460px]">
            <Button
              color="#e3e3e3"
              title="Cancelar"
              handleFunction={handleCancelButton}
            />

            <Button
              isLoading={loading}
              type="submit"
              title="Continuar"
            />
          </div>
        </form>
      </main>
    </div>
  );
};
