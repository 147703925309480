import React, { FormEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Loading } from "../../components/loading";
import { useAuth } from "../../hooks/auth";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import { ExclamationIcon } from '@heroicons/react/solid';

import {
  ButtonEyeVisibility,
  ButtonSignIn,
  Container,
  Content,
  EyeDisabledIcon,
  EyeEnabledIcon,
  ForgetPassword,
  Input,
  InputPassword,
  Line,
  NotHaveAnAccount,
  Reference,
  Separator,
  // StricLogo,
  Title,
  WrapperBackground,
  WrapperContent,
  WrapperForm,
  WrapperLogo,
  Error,
} from "./styles";
import { api, parseError } from "../../services/api";
import { useClient } from "../../hooks/client";
import { CheckIcon } from "@heroicons/react/outline";


export const Forgot: React.FC = () => {
  const { client } = useClient();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const navigation = useNavigate();
  const location = useLocation();
  const { signIn, account } = useAuth();

  const [document, setDocument] = useState('');
  const [password, setPassword] = useState('');

  const [success, setSuccess] = useState('');

  const [step, setStep] = useState(1);
  const [token, setToken] = useState('');

  const handlePasswordVisibility = () => {
    setIsPasswordVisible((state) => !state);
  };

  useEffect(() => {
    if (account) {
      navigation("/");
    }
  });

  useEffect(() => {
    if (!location.search || location.search === null || location.search === '') {
      setStep(1);
    } else {
      setStep(2);
      setToken(location.search.replace('?token=', ''));
    }
  }, [location.search]);


  const handleSignIn = async (event: FormEvent) => {
    event.preventDefault();

    if (!document) {
      return;
    }

    try {
      setLoading(true);
      setError('');
      await api.post("/accounts/send/password", {
        email: document.toLowerCase(),
      }, {
        headers: {
          client: client.client_id,
        }
      });

      setLoading(false);
      setError('');
      setSuccess('Foi enviado as instruções de recuperarção de senha para o seu e-mail.');
    } catch (err) {
      const error = parseError(err);
      setLoading(false);
      setError('');
      setError(error.friend);
    }
  };


  const handleReset = async (event: FormEvent) => {
    event.preventDefault();

    if (!password) {
      return;
    }

    try {
      setLoading(true);
      setError('');
      await api.post("/accounts/reset/password", {
        token,
        password
      }, {
        headers: {
          client: client.client_id,
        }
      });

      setLoading(false);
      setError('');
      setSuccess('Nova senha cadastrada com sucesso.');
    } catch (err) {
      const error = parseError(err);
      setLoading(false);
      setError('');
      setError(error.friend);
    }
  };

  return (
    <Container>
      <WrapperBackground />
      <WrapperContent>
        <Content>
          <WrapperLogo>
            <img src={client.whiteLogo.image} alt={client.name} style={{
              width: client.whiteLogo.maxWidth,
              height: client.whiteLogo.maxHeight,
              }} />
            {/* <StricLogo /> */}
          </WrapperLogo>
          <Title>
            Bem-vindo ao internet <br />
            <b>banking da {client.name}</b>
          </Title>
          <Separator />
          <Reference>
            Preencha os campos abaixo para recuperar sua senha
          </Reference>
          {step === 1? (
            <WrapperForm onSubmit={handleSignIn}>
              <Input>
                <span>E-mail:</span>
                <input
                  placeholder="Digite seu E-mail" type="text"
                  onChange={(input: any) => setDocument(input.target.value)}
                  value={document}
                />
              </Input>

              {!!success && (
                <Error>
                  <div className="bg-green-50 border-l-4 border-green-400 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <CheckIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-gray-700">
                          {success}
                        </p>
                      </div>
                    </div>
                  </div>
                </Error>
              )}

              {!!error && (
                <Error>
                  <div className="bg-red-50 border-l-4 border-red-400 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-red-700">
                          {error}
                        </p>
                      </div>
                    </div>
                  </div>
                </Error>
              )}
              <ButtonSignIn type="submit">
                <Loading isLoading={loading} />
                Continuar
              </ButtonSignIn>
            </WrapperForm>

          ) : (
            <WrapperForm onSubmit={handleReset}>
              <Input>
                <span>Informe sua nova senha</span>
                <input
                  placeholder="Nova senha" type="text"
                  onChange={(input: any) => setPassword(input.target.value)}
                  value={password}
                />
              </Input>

              {!!success && (
                <Error>
                  <div className="bg-green-50 border-l-4 border-green-400 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <CheckIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-gray-700">
                          {success}
                        </p>
                      </div>
                    </div>
                  </div>
                </Error>
              )}

              {!!error && (
                <Error>
                  <div className="bg-red-50 border-l-4 border-red-400 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-red-700">
                          {error}
                        </p>
                      </div>
                    </div>
                  </div>
                </Error>
              )}
              <ButtonSignIn type="submit">
                <Loading isLoading={loading} />
                Continuar
              </ButtonSignIn>
            </WrapperForm>

          )}
          <ForgetPassword href="/signin">Voltar para o login</ForgetPassword>
          <Line />
          {/* <NotHaveAnAccount to="/type-create-account">
            Não tem uma conta? Abrir conta {client.name}
          </NotHaveAnAccount> */}
        </Content>
      </WrapperContent>
    </Container>
  );
};
