import React, { useState } from "react";
import { Button } from "../../components/button";
import TextInput from "../../components/TextInput";
import { useAuth } from "../../hooks/auth";
import { useClient } from "../../hooks/client";
import { useNotification } from "../../hooks/notification";
import { api } from "../../services/api";

import {
  ContentWrapper,
  Container,
  Content,
  Table,
  TableWrapper,
  Text,
} from "./styles";
import { Lock } from "lucide-react";

export const MyAccount: React.FC = () => {

  const [current, setCurrent] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setrepeatPassword] = useState('');

  const { account } = useAuth();
  const { client } = useClient();

  const [loading, setLoading] = useState(false);

  const { setOpenNotification } = useNotification();

  async function handlePassword() {
    setLoading(true);

    if (password !== repeatPassword) {
      setOpenNotification({
        type: 'error',
        title: 'Não foi possível',
        notification: 'A confirmação de senha precisa ser igual a nova senha.'
      });

      setLoading(false);
      return;
    }

    try {
      await api.post('/accounts/session', {
        document: account.document,
        password: current,
      }, {
        headers: {
          client: client.client_id,
        }
      });

      const { data } = await api.post("/accounts/send/password", {
        email: account.email,
        send: false,
      }, {
        headers: {
          client: client.client_id,
        }
      });


      await api.post("/accounts/reset/password", {
        token: data.id,
        password,
      }, {
        headers: {
          client: client.client_id,
        }
      });

      setLoading(false);
      setOpenNotification({
        type: 'success',
        title: 'Senha alterada',
        notification: 'Senha alterada com sucesso.'
      });
    } catch (err) {
      setLoading(false);
      setOpenNotification({
        type: 'error',
        title: 'Não foi possível',
        notification: 'Senha atual inválida'
      });
    }
  }


  return (
    <Container>
      <Content>
        <ContentWrapper>
          <Table>
            <TableWrapper>
              <div className="p-5 w-96 space-y-5 pointer-events-none">
                <h1 className="text-2xl">Dados Pessoais</h1>
                <div className="opacity-60">
                  <label>Nome:</label>
                  <div className="relative">
                    <TextInput
                      type="text"
                      value={account.name}
                    />
                    <Lock className="h-4 w-4 absolute right-3 top-3" />
                  </div>
                </div>
                <div className="opacity-60">
                  <label>CPF:</label>
                  <div className="relative">
                    <TextInput
                      type="text"
                      value={account.document}
                    />
                    <Lock className="h-4 w-4 absolute right-3 top-3" />
                  </div>

                </div>
                <div className="opacity-60">
                  <label>E-mail:</label>
                  <div className="relative">
                    <TextInput
                      type="text"
                      value={account.email}
                    />
                    <Lock className="h-4 w-4 absolute right-3 top-3" />
                  </div>
                </div>
              </div>
              <div className="p-5 w-96">
                <h1 className="text-2xl">Senha</h1>

                <div className="mt-5">
                  <label htmlFor="current-password">Senha atual</label>
                  <TextInput
                    type="password"
                    id="current-password" value={current} onChange={input => setCurrent(input.target.value)} />
                </div>

                <div className="mt-5">
                  <label htmlFor="current-password1">Nova senha</label>
                  <TextInput type="password" id="current-password1" value={password} onChange={input => setPassword(input.target.value)} />
                </div>

                <div className="mt-5 mb-5">
                  <label htmlFor="current-password2">Repita nova senha</label>
                  <TextInput type="password" id="current-password2" value={repeatPassword} onChange={input => setrepeatPassword(input.target.value)} />
                </div>

                <Button type="button" title="Salvar" isLoading={loading} handleFunction={handlePassword} />
              </div>


            </TableWrapper>
          </Table>
        </ContentWrapper>
      </Content>
    </Container >
  );
};
