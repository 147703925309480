export function formatName(
  name: string | undefined,
  wordsToExclude: string[] = [],
) {
  if (!name) return ''

  let excludeWords: string[] = ['LTDA', 'S.A.', 'BRASIL']

  if (wordsToExclude) {
    excludeWords = [...new Set([...wordsToExclude, ...excludeWords])]
  }

  const names = name.split(' ')

  let formattedName = ''

  for (const name of names) {
    const shouldExclude = excludeWords.some((excludedWord) =>
      name.toLowerCase().includes(excludedWord.toLowerCase()),
    )

    if (name.length > 0 && !shouldExclude) {
      if (formattedName !== '') {
        formattedName += ' '
      }
      formattedName += name[0].toUpperCase() + name.slice(1).toLowerCase()
    } else {
      if (formattedName !== '') {
        formattedName += ' '
      }
      formattedName += name
    }
  }

  return formattedName
}
