import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { Header } from "../../components/header";
import ModalReference from "../../components/ModalReference";
import { Sidebar } from "../../components/sidebar";

import { Container, Content } from "./styles";

export const Layout: React.FC = () => {
  const [modalReferenceIsOpen, setModalReferenceIsOpen] = useState(false);

  const handleCloseReferenceModal = () => {
    setModalReferenceIsOpen(false);
  };

  const [SidebarMobileisactive, setSidebarMobileisactive] = useState(false)

  const handleSidebarMobileToggle = () => {
    setSidebarMobileisactive((state) => !state)
  }

  return (
    <Container>
      <Sidebar
        isActive={SidebarMobileisactive}
        handleSidebarMobileToggle={handleSidebarMobileToggle}
      />

      <Content>
        <Header
          handleSidebarFunction={handleSidebarMobileToggle}
          sidebarisactive={SidebarMobileisactive}
        />

        <Outlet />
      </Content>
      <ModalReference
        isOpen={modalReferenceIsOpen}
        handleClose={handleCloseReferenceModal}
      />

    </Container>
  );
};
