import { useNavigate } from 'react-router-dom'
import { ChevronLeft } from 'lucide-react'

interface Props {
  title: string
}

export function ReturnBack({ title }: Props) {
  const navigate = useNavigate()

  return (
    <div className="mb-6 flex items-center ">
      <div
        onClick={() => navigate(-1)}
        className="mr-4 flex h-10 w-10 cursor-pointer items-center justify-center rounded bg-white shadow-sm hover:opacity-80"
      >
        <ChevronLeft className="h-7 w-7 text-primary" strokeWidth={2.2} />
      </div>
      <h1 className="mt-1 text-2xl text-tx-primary">{title}</h1>
    </div>
  )
}
