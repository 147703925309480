import { AnimatePresence } from "framer-motion";
import React, { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/auth";
import { documentFormatted } from "../../utils/documentFormatted";
import IconHeader from "../IconHeader";
import { BellMessage } from "../bellMessage";
import { ClientInfoAccount } from "../clientInfoAccount";
import {
  ArrowPageRightIcon,
  BellIcon,
  BellWrapper,
  Box,
  BoxNotifications,
  BoxWrapper,
  ButtonBell,
  ClientInfo,
  DashboardInfo,
  HeaderWrapper,
  SectionInfo,
  SectionTitle,
  SidebarIcon,
} from "./styles";

import { cn } from "../../utils/cn";
import { CloseIcon } from "../icons/close-icon";
import { IconSwitch } from "./SwitchIcon";

interface Props {
  sidebarisactive: boolean
  handleSidebarFunction: () => void
}
export const Header: React.FC<Props> = ({
  sidebarisactive,
  handleSidebarFunction
}) => {
  const routes = useLocation();
  const { account } = useAuth();

  const routesNames = useMemo(() => {
    return [
      {
        path: "/",
        name: "Início",
        iconName: "HomeIcon",
      },
      {
        path: "/receipt",
        name: "Extrato da conta",
        iconName: "ReceiptIcon",
      },
      {
        path: "/extract",
        name: "Extrato da conta",
        iconName: "ReceiptIcon",
      },
      {
        path: "/deposit",
        name: "Depositar",
        iconName: "DepositIcon",
      },
      {
        path: "/deposit/tedordoc",
        name: "Depositar",
        subname: "TED ou DOC",
        iconName: "DepositIcon",
      },
      {
        path: "/deposit/deposit-pix",
        name: "Depositar",
        subname: "Pix",
        iconName: "DepositIcon",
      },
      {
        path: "/deposit/invoice",
        name: "Depositar",
        subname: "Boleto",
        iconName: "DepositIcon",
      },
      {
        path: "/deposit/invoice/confirm",
        name: "Depositar",
        subname: "Boleto",
        iconName: "DepositIcon",
      },
      {
        path: "/deposit/invoices-list",
        name: "Depositar",
        subname: "Boleto",
        iconName: "DepositIcon",
      },
      {
        path: "/charge",
        name: "Gestão de cobrança",
        iconName: "ChargeIcon",
      },
      {
        path: "/pix",
        name: "Área PIX",
        iconName: "PixIconSVG",
      },
      {
        path: "/pix/list",
        name: "Área PIX",
        iconName: "PixIconSVG",
      },
      {
        path: "/pix/transfer",
        name: "Área PIX",
        iconName: "PixIconSVG",
      },
      {
        path: "/pix/transfer/confirm",
        name: "Pagar por PIX",
        iconName: "PixIconSVG",
      },
      {
        path: "/myaccount",
        name: "Minha Conta",
        iconName: "UserIcon",
      },
      {
        path: "/pix/mykeys",
        name: "Minhas chaves",
        // subname: "Minhas chaves",
        iconName: "PixIconSVG",
      },
      {
        path: "/pix/mykeys/new",
        name: "Nova chave",
        // subname: "Minhas chaves",
        iconName: "PixIconSVG",
      },
      {
        path: "/paybills",
        name: "Pagar contas",
        iconName: "BillIcon",
      },
      {
        path: "/paybills/invoice",
        name: "Pagar contas",
        subname: "Pagar por Boleto",
        iconName: "BillIcon",
      },
      {
        path: "/paybills/info-invoice",
        name: "Pagar contas",
        subname: "Pagar por Boleto",
        iconName: "BillIcon",
      },
      {
        path: "/paybills/ticket",
        name: "Pagar contas",
        subname: "Pagar por Boleto",
        iconName: "BillIcon",
      },
      {
        path: "/paybills/confirm-invoice",
        name: "Pagar contas",
        subname: "Pagar por Boleto",
        iconName: "BillIcon",
      },
      {
        path: "/paybills/subscribe",
        name: "Pagar contas",
        subname: "Cadastrar favorecido",
        iconName: "BillIcon",
      },
      {
        path: "/paybills/schedule-payments",
        name: "Pagar contas",
        subname: "Pagamentos agendados",
        iconName: "BillIcon",
      },
      {
        path: "/extract",
        name: "Pagar contas",
        subname: "Pagamento de boleto",
        iconName: "ChargeIcon",
      },
      {
        path: "/transfer",
        name: "Transferir TED/DOC",
        iconName: "BankMoney",
      },
      {
        path: "/transfer/confirm",
        name: "Transferir TED/DOC",
        iconName: "BankMoney",
      },
      {
        path: "/transfer/list",
        name: "Transferir TED/DOC",
        iconName: "BankMoney",
      },
      {
        path: "/transfer/subscribe",
        name: "Transferir TED/DOC",
        subname: "",
        iconName: "BankMoney",
      },
      {
        path: "/tax",
        name: "Tarifas",
        iconName: "TaxIcon",
      },
    ];
  }, []);

  const currentRouteInfo = useMemo(() => {
    // eslint-disable-next-line array-callback-return
    return routesNames.find((route) => {
      if (routes.pathname === route.path) {
        return route;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routes.pathname]);

  const [BellBoxMessageIsActive, setBoxMessageIsActive] = useState(false);
  // const [otherAccountsIsActive, setOtherAccountsIsActive] = useState(false);

  const HandleBoxMessageToggle = () => {
    setBoxMessageIsActive((state) => !state);
  };

  // const HandleOtherAccountsToggle = () => {
  //   setOtherAccountsIsActive((state) => !state);
  // };

  return (
    <header className="sticky left-0 top-0 flex">
      <button
        onClick={handleSidebarFunction}
        className={cn(
          'flex h-20 w-20 items-center justify-center  border-b border-gray-bg bg-gray-bg bg-none hover:opacity-95 sm:hidden',
        )}
      >
        {!sidebarisactive ? (
          <SidebarIcon className="h-6 w-6 text-primary" />
        ) : (
          <CloseIcon className="h-5 w-5 text-primary" />
        )}
      </button>
      <HeaderWrapper>
        <SectionInfo>
          <IconHeader Icon={IconSwitch(currentRouteInfo?.iconName!)} />

          <SectionTitle>{currentRouteInfo?.name}</SectionTitle>

          {currentRouteInfo?.subname && (
            <div className="flex justify-center items-center ">
              <ArrowPageRightIcon />
              <SectionTitle>{currentRouteInfo?.subname}</SectionTitle>
            </div>
          )}
        </SectionInfo>
        <DashboardInfo>
          <BellWrapper>
            <ButtonBell onClick={HandleBoxMessageToggle}>
              <BellIcon />
            </ButtonBell>

            <AnimatePresence>
              {BellBoxMessageIsActive && (
                <BoxWrapper
                  initial={{ opacity: 0, y: "-10%" }}
                  animate={{ opacity: 1, y: "0%" }}
                  exit={{ opacity: 0, y: "-10%" }}
                  transition={{ duration: 0.2 }}
                >
                  <Box>
                    <span></span>
                    <BoxNotifications>
                      <BellMessage
                        title="Nenhuma mensagem"
                        link="#"
                        desc="Nenhuma mensagem pendente"
                      // Você recebeu uma transferência de ROSANGELA ANTUNES FERREIRA no valor de R$ 1.245,23
                      />
                    </BoxNotifications>
                  </Box>
                </BoxWrapper>
              )}
            </AnimatePresence>
          </BellWrapper>
          <ClientInfo>
            <ClientInfoAccount
              // handleFunction={HandleOtherAccountsToggle}
              isSelected={true}
              name={account.name}
              id={documentFormatted(account.document)}
            />
            {/* <AnimatePresence>
              {otherAccountsIsActive && (
                <OtherAccounts
                  initial={{ opacity: 0, y: "-10px" }}
                  animate={{ opacity: 1, y: "0px" }}
                  exit={{ opacity: 0, y: "-10px" }}
                  transition={{ duration: 0.2, type: "tween" }}
                >
                  <ClientInfoAccount
                    isSelected={false}
                    name={"Martin Ferramentas"}
                    id={"32.241.521/0001-23"}
                  />
                  <ClientInfoAccount
                    isSelected={false}
                    name={"Martin Ferramentas"}
                    id={"32.241.521/0001-23"}
                  />
                </OtherAccounts>
              )}
            </AnimatePresence> */}
          </ClientInfo>
        </DashboardInfo>
      </HeaderWrapper>
    </header>
  );
};
