import styled, { css, keyframes } from "styled-components";

import {ReactComponent as StricLogoSVG} from '../../assets/stric_logo.svg'
import {ReactComponent as SidebarIconSVG} from '../../assets/sidebarIcon.svg'
import {ReactComponent as VisionIconSVG} from '../../assets/vision.svg'
import {ReactComponent as HomeIconSVG} from '../../assets/home.svg'
import {ReactComponent as ReceiptIconSVG} from '../../assets/receipt.svg'
import {ReactComponent as BankMoneySVG} from '../../assets/bankmoney.svg'
import {ReactComponent as ArrowIconSVG} from '../../assets/arrow.svg'

import {ReactComponent as signOutIconSVG} from '../../assets/sign-out.svg'
import {ReactComponent as HelpIconSVG} from '../../assets/help.svg'
import {ReactComponent as AccountDeclarationIconSVG} from '../../assets/accountDeclaration.svg'
import {ReactComponent as TaxIconSVG} from '../../assets/tax.svg'
import {ReactComponent as BillIconSVG} from '../../assets/billSide.svg'
import {ReactComponent as PixIconSVG} from '../../assets/pix.svg'
import {ReactComponent as ChargeIconSVG} from '../../assets/chargeSide.svg'
import {ReactComponent as DepositIconSVG} from '../../assets/deposit.svg'
import {ReactComponent as transferMoneyIconSVG} from '../../assets/transferSide.svg'
import {ReactComponent as ShareIconSVG} from '../../assets/share.svg'
import {ReactComponent as UserProfileIconSVG} from '../../assets/user-profile.svg'



import { motion } from "framer-motion";


interface PropsArrowIcon {
    bankServicesIsOpen?: boolean;
}

interface SidebarContainerProps {
    isActive: boolean;
}

type OptionsServicesProps = {
    isActive: boolean;
}

const iconStyle = css`

fill: var(--white-light);
width: 16px;

`

export const StricLogo = styled(StricLogoSVG)`
margin-left: 24px;
`


export const ShareIcon = styled(ShareIconSVG)`${iconStyle}`
export const UserProfileIcon = styled(UserProfileIconSVG)`${iconStyle}`

export const SidebarIcon = styled(SidebarIconSVG)`${iconStyle}`
export const VisionIcon = styled(VisionIconSVG)`
${iconStyle}
fill: var(--cian);
`
export const HomeIcon = styled(HomeIconSVG)`${iconStyle}`
export const ReceiptIcon = styled(ReceiptIconSVG)`${iconStyle}`
export const BankMoneyIcon = styled(BankMoneySVG)`${iconStyle}`
export const Arrowicon = styled(ArrowIconSVG)<PropsArrowIcon>`

${iconStyle}
transform: ${(props) => props.bankServicesIsOpen ? 'rotate(0)' : 'rotate(180deg)'};

`

export const TransferMoneyIcon = styled(transferMoneyIconSVG)`${iconStyle}`
export const DepositIcon = styled(DepositIconSVG)`${iconStyle}`
export const ChargeIcon = styled(ChargeIconSVG)`${iconStyle}`
export const PixIcon = styled(PixIconSVG)`${iconStyle}`
export const BillIcon = styled(BillIconSVG)`${iconStyle}`
export const AccountDeclarationIcon = styled(AccountDeclarationIconSVG)`${iconStyle}`
export const HelpIconIcon = styled(HelpIconSVG)`${iconStyle}`
export const SignOutIcon = styled(signOutIconSVG)`${iconStyle}`
export const TaxIcon = styled(TaxIconSVG)`${iconStyle}`

type optionSidebarProps = {
    isSelected: boolean;
}

export const Container = styled.div<SidebarContainerProps>`

grid-area: sidebar;
background-color: var(--gray);
height: 100%;
width: 256px;
display: flex;
flex-direction: column;
padding-bottom: 32px;
position: fixed;
z-index: 1;
top: 0;
left: 0;
overflow-y: scroll;
transition: all .2s;

@media (max-width: 720px){
    ${props => props.isActive ?
    css`
    left: 0;
    ` : css`
    left: -256px;
    `};
}

@media (min-width: 720px){
    display: block;
}


::-webkit-scrollbar {
        visibility: hidden;
        display: none;
    } /* Chorme, Safari */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

`

export const WrapperHeader = styled.div`

background-color: var(--cian-dark);

min-height: 80px;
display: flex;
align-items: center;
padding: 38px 24px;


`

export const SideFix = styled.div`

padding: 22px 0px 0;
display: flex;
flex-direction: column;

`
export const OptionsWrapper = styled(motion.div).attrs({
    initial: { opacity: 0, y: '-10%' },
    animate: { opacity: 1, y: '0' },
    exit: { opacity: 0, y: '-10%' },
    ransition: {type: 'just', duration: 1}
})`

padding: 22px 0 0;

`




export const OptionSidebarFix = styled.div<optionSidebarProps>`

${props => props.isSelected &&

css`

    &::before {
        content: '';
        height: 16px;
        width: 4px;
        background-color: var(--cian);
        position: absolute;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
    }

`
}

height: 24px;
position: relative;

> a {
    text-decoration: none;
    padding: 0 24px;
    display: flex;
    background: none;
    border-style: none;
    cursor: pointer;
    width: 100%;

    > span {
    color: ${props => props.isSelected ? "var(--cian)" : "var(--white)"};
    font-size: 16px;
    font-weight: 100;
    font-family: var(--light);
    }

    > div {
        > svg {
            ${props => props.isSelected && css`
            fill: var(--cian);
            ` }
        }
    }
}

& + div {
    margin-top: 22px;
}


`

export const NotificationWrapper = styled.div`

height: 15px;
width: 15px;
border-radius: 2px;

display: flex;
align-items: center;
justify-content: center;

background: var(--red);

position: absolute;
right: 24px;
margin-top: auto;
margin-bottom: auto;
top: 0;
bottom: 0;

> span {
    font-size: 12px;
    font-family: var(--regular);
    color: var(--white);
}

`

export const IconWrapper = styled.div`

display: flex;
justify-content: center;
margin-right: 24px;

`



export const Icon = styled.img`

width: 16px;

`


export const InfoAccount = styled.div`

display: flex;
flex-direction: column;
justify-content: center;
height: 96px;
padding: 24px;
border-bottom: 2px solid var(--line);

> p {
    font-size: 16px;
    color: var(--cian);
    font-family: var(--light);
}

`

export const BalanceInfoAccount = styled.div`

display: flex;
align-items: center;
justify-content: space-between;

> p {
    font-size: 24px;
    color: var(--white);
    font-family: var(--semiBold);
}

> button {
    background: none;
    border-style: none;
    cursor: pointer;
}

`

export const Service = styled.div`

padding: 16px 24px;

`



export const ServiceHeader = styled.div`

display: flex;
justify-content: space-between;
margin-bottom: 36px;
align-items: center;

`



export const ServiceTitle = styled.p`

font-size: 16px;
color: var(--white);
font-family: var(--bold);
`

export const ServiceIcon = styled.img``
