import { endOfMonth, startOfMonth, subHours } from "date-fns";
import React, { Fragment, useEffect, useState } from "react";
import { Menu, Dialog, Transition } from '@headlessui/react'
import { PaperClipIcon, XIcon, PrinterIcon } from '@heroicons/react/outline'
import { Button } from "../../components/button";
import ModalConfirmOperation from "../../components/ModalConfirmOperation";
import { Skeleton } from "../../components/skeleton";
import { useNotification } from "../../hooks/notification";
import { api } from "../../services/api";
import { currencyFormat } from "../../utils/currencyFormat";
import { dateFormat } from "../../utils/dateFormat";
import { normalizeName } from "../../utils/normelizaName";
import DatePicker, { registerLocale, } from "react-datepicker";


import pt from 'date-fns/locale/pt';


import "react-datepicker/dist/react-datepicker.css";


import {
  ArrowPageLeftIcon,
  ArrowPageRightIcon,
  Content,
  PaginationWrapper,
  Table,
  TableWrapper,
  Container,
  DownloadIcon,
} from "./styles";
import { documentFormatted } from "../../utils/documentFormatted";
import { CircleNotch } from "phosphor-react";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

registerLocale('pt', pt);

interface IItems {
  id: string;
  description: string;
  balanceAfter: number;
  date: string;
  status: string;
  type: string;
  credit: boolean;
  amount: number;
}

export interface Transaction {
  id: string
  credit: boolean
  amount: number
  type: string
  typeId: string
  description: string
  status: string
  balanceAfter: number
  date: string
}

export interface Detail {
  id: string
  credit: boolean
  amount: number
  description: string
  rateValue: number
  consignorName: string
  consignorDocument: string
  consignorBankNumber: string
  consignorBankBranch: string
  consignorBankAccount: string
  consignorBankAccountDigit: string
  beneficiaryName: string
  beneficiaryDocument: string
  beneficiaryBankNumber: string
  beneficiaryBankBranch: string
  beneficiaryBankAccount: string
  beneficiaryBankAccountDigit: string
  status: string
  date: string
}

export interface ShowTransaction {
  transaction: Transaction;
  detail: Detail;
}


export const Receipt = () => {
  const [loading, setLoading] = useState(true);
  const [isExporting, setIsExporting] = useState(false);
  const [items, setItems] = useState<IItems[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const { setOpenNotification } = useNotification();
  const start = startOfMonth(new Date());
  const end = endOfMonth(new Date());


  const [open, setOpen] = useState(false);
  const [current, setCurrent] = useState<ShowTransaction>({} as ShowTransaction);

  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end);
  // const [isExporting, setIsExporting] = useState(false);




  useEffect(() => {
    setLoading(true);
    const dateEnd = subHours(endDate, 3);
    api.get(`/transactions?page=${currentPage}&start=${startDate}&end=${dateEnd}`).then(({ data }) => {
      setTotalPages(data.total_pages + 1);
      setCurrentPage(data.current_page);
      setItems(data.items);
      console.log(data.items);

      setLoading(false);
    });
  }, [currentPage, startDate, endDate]);

  const [modalIsOpen, setIsOpen] = useState(false);


  async function showTransaction(item: Transaction) {
    if (item.credit) {
      return;
    }

    setOpen(true);

    try {
      const { data } = await api.get(`/transactions/${item.id}`);
      setCurrent(data);
    } catch (err) {
      //
    }
  }

  function afterOpenModal() {
  }

  function closeModal() {
    setIsOpen(false);
  }

  function handlePrevious() {
    if (currentPage + 1 > 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function handleNext() {
    if (currentPage + 1 < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  }

  function getType(type: string): string {
    if (type === 'PIX') {
      return "Pix";
    }

    if (type === 'TED') {
      return "Transferência";
    }

    if (type === 'P2P') {
      return "Transferência";
    }

    if (type === 'BILLET') {
      return "Boleto";
    }

    if (type === 'PAYMENT') {
      return "Pagamento";
    }

    if (type === 'INITIAL') {
      return '';
    }

    return type;
  }

  function handleExport() {
    setIsExporting(true);
    function getFileToDownload(apiUrl: string) {
      return api.get(apiUrl, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }

    getFileToDownload(`/transactions/formatted/file?start=${startDate}&end=${endDate}`)
      .then(response => {
        const type = response.headers['content-type']
        const blob = new Blob([response.data], { type: type, })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'extract.pdf'
        link.click()
        setIsExporting(false);
        setOpenNotification({ title: 'Extrato Exportado', type: 'success', notification: 'Extrato exportado com sucesso' });
      }).catch(err => {
        setIsExporting(false);
        setOpenNotification({ title: 'Erro', type: 'error', notification: 'Não foi possível exportar o extrato' });
      })
  }

  function handleExportExcel() {
    setIsExporting(true);
    function getFileToDownload(apiUrl: string) {
      return api.get(apiUrl, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }

    getFileToDownload(`/transactions/formatted/file?start=${startDate}&end=${endDate}&type=xlsx`)
      .then(response => {
        const type = response.headers['content-type']
        const blob = new Blob([response.data], { type: type, })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'extract.xlsx'
        link.click()
        setIsExporting(false);
        setOpenNotification({ title: 'Extrato Exportado', type: 'success', notification: 'Extrato exportado com sucesso' });
      }).catch(err => {
        setIsExporting(false);
        setOpenNotification({ title: 'Erro', type: 'error', notification: 'Não foi possível exportar o extrato' });
      })
  }

  function handleDownload(id: string) {
    setIsExporting(true);
    function getFileToDownload(apiUrl: string) {
      return api.get(apiUrl, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }

    getFileToDownload(`/transactions/file/${id}`)
      .then(response => {
        const type = response.headers['content-type']
        const blob = new Blob([response.data], { type: type, })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `comprovante-${id}.pdf`;
        link.click();
        setIsExporting(false);
        setOpenNotification({ title: 'Extrato Exportado', type: 'success', notification: 'Extrato exportado com sucesso' });
      }).catch(err => {
        setIsExporting(false);
        setOpenNotification({ title: 'Erro', type: 'error', notification: 'Não foi possível exportar o extrato' });
      })
  }


  return (
    <>
      <ModalConfirmOperation
        closeModal={closeModal}
        modalIsOpen={modalIsOpen}
        afterOpenModal={afterOpenModal}
      />
      <Container>
        <Content>
          <Table>
            <div className="flex justify-between items-center mb-5">
              <div>
                <h3 className="text-xl">Período</h3>
                <span>Selecione um período para filtrar:</span>
                <div className="flex mt-2">
                  <DatePicker
                    className="bg-[#142249] rounded px-2 py-1 text-center text-white flex items-center"
                    selected={startDate}
                    onChange={(date: Date) => setStartDate(date)}
                    locale="pt"
                    dateFormat="dd/MM/yyyy"
                  />
                  <span className="px-2 py-1">até</span>
                  <DatePicker
                    className="rounded px-2 py-1 text-center bg-[#142249] text-white flex items-center"
                    selected={endDate}
                    onChange={(date: Date) => setEndDate(date)}
                    locale="pt"
                    dateFormat="dd/MM/yyyy"
                  />
                </div>

              </div>
              <div className="flex flex-end">
                {/* <Button title="Exportar" isLoading={isExporting} handleFunction={handleExport}/> */}

                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none">
                      <span className="sr-only">Open options</span>
                      <Button title="Exportar" isLoading={isExporting} Icon={DownloadIcon} />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <span
                              onClick={handleExport}
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm cursor-pointer'
                              )}
                            >
                              PDF
                            </span>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <span
                              onClick={handleExportExcel}
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm cursor-pointer'
                              )}
                            >
                              Excel (xlsx)
                            </span>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>

            <TableWrapper>


              {!loading && items.length <= 0 && (
                <div className="p-6">
                  <div className="text-center">
                    <h3 className="mt-2 text-sm font-medium text-gray-900">Nenhum lançamento para o período selecionado</h3>
                    <p className="mt-1 text-sm text-gray-500">Selecione outro período ou faça uma transferência e comece a usar agora mesmo</p>

                  </div>
                </div>
              )}

              <Skeleton space={4} isActive={loading} repeat={5} />

              <table>
                <tbody>
                  {!loading && items.length > 0 && (
                    <tr>
                      <th>Data</th>
                      <th>Tipo</th>
                      <th>Descrição</th>
                      <th>Valor</th>
                    </tr>
                  )}


                  {!loading && items.map((item) => {
                    return (
                      <tr key={item.id} onClick={() => showTransaction(item as Transaction)}>
                        <td>{dateFormat(item.date)}</td>
                        <td>
                          {getType(item.type)}
                          {item.credit ? ' - Crédito' : ' - Débito'}
                        </td>
                        <td>{normalizeName(item.description)}</td>
                        <td>
                          <span style={{ color: item.credit ? 'var(--green)' : 'var(--red)' }}>
                            {!item.credit && item.amount > 0 ? '-' : '+'} {currencyFormat(item.amount)}
                          </span>

                          <span style={{ marginLeft: '25px', display: !item.credit && item.amount > 0 ? 'inline' : 'none' }}>
                            <PrinterIcon color="var(--boxText)" className="h-4 w-4 inline" />
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </TableWrapper>
          </Table>
          <PaginationWrapper>
            <ArrowPageLeftIcon onClick={handlePrevious} />
            <span>Página {currentPage + 1} de {totalPages}</span>
            <ArrowPageRightIcon onClick={handleNext} />
          </PaginationWrapper>
        </Content>
      </Container>

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => {
          setOpen(false);
          setCurrent({} as ShowTransaction);
        }}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-lg font-medium text-gray-900">Comprovante</Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6">
                        {current?.detail ? (
                          <div className="absolute inset-0 px-4 sm:px-6">
                            <div className="h-full border-2 border-dashed border-gray-200" aria-hidden="true">
                              <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                                <div className="px-4 py-5 sm:px-6">
                                  <span>De</span>
                                  <h3 className="text-lg leading-6 font-medium text-gray-900">{current.detail.consignorName}</h3>
                                  <p className="mt-1 max-w-2xl text-sm text-gray-500">{documentFormatted(current.detail.consignorDocument)}</p>
                                </div>
                                <div className="px-4 py-5 sm:px-6">
                                  <span>Para</span>
                                  <h3 className="text-lg leading-6 font-medium text-gray-900">{current.detail.beneficiaryName}</h3>
                                  <p className="mt-1 max-w-2xl text-sm text-gray-500">{documentFormatted(current.detail.beneficiaryDocument)}</p>
                                </div>
                                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                    <div className="sm:col-span-1">
                                      <dt className="text-sm font-medium text-gray-500">Nome</dt>
                                      <dd className="mt-1 text-sm text-gray-900">{current.detail.beneficiaryName}</dd>
                                    </div>
                                    <div className="sm:col-span-1">
                                      <dt className="text-sm font-medium text-gray-500">Documento</dt>
                                      <dd className="mt-1 text-sm text-gray-900">{current.detail.beneficiaryDocument}</dd>
                                    </div>
                                    <div className="sm:col-span-1">
                                      <dt className="text-sm font-medium text-gray-500">Banco</dt>
                                      <dd className="mt-1 text-sm text-gray-900">{current.detail.beneficiaryBankNumber}</dd>
                                    </div>
                                    <div className="sm:col-span-1">
                                      <dt className="text-sm font-medium text-gray-500">Agência/Conta</dt>
                                      <dd className="mt-1 text-sm text-gray-900">{current.detail.beneficiaryBankNumber}/{current.detail.beneficiaryBankAccount}-{current.detail.beneficiaryBankAccountDigit}</dd>
                                    </div>
                                    <div className="sm:col-span-1">
                                      <dt className="text-sm font-medium text-gray-500">Data</dt>
                                      <dd className="mt-1 text-sm text-gray-900">{dateFormat(current.detail.date)}</dd>
                                    </div>
                                    <div className="sm:col-span-1">
                                      <dt className="text-sm font-medium text-gray-500">Valor</dt>
                                      <dd className="mt-1 text-sm text-gray-900">{currencyFormat(current.detail.amount)}</dd>
                                    </div>
                                    <div className="sm:col-span-2">
                                      <dt className="text-sm font-medium text-gray-500">Comprovante (PDF)</dt>
                                      <dd className="mt-1 text-sm text-gray-900">
                                        <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                                          <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                                            <div className="w-0 flex-1 flex items-center">
                                              <PaperClipIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                                              <span className="ml-2 flex-1 w-0 truncate">comprovante.pdf</span>
                                            </div>
                                            <div className="ml-4 flex-shrink-0">
                                              <span onClick={() => handleDownload(current.transaction.id)} className="cursor-pointer font-medium text-indigo-600 hover:text-indigo-500 flex items-center">
                                                {isExporting && <CircleNotch weight="bold" className="w-5 h-5 animate-spin mr-2" />} Fazer download
                                              </span>
                                            </div>
                                          </li>
                                        </ul>
                                      </dd>
                                    </div>
                                  </dl>
                                </div>
                              </div>

                            </div>
                          </div>
                        ) : (
                          <div>

                          </div>
                        )}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
