import React from "react";
import { ModalInvoiceContextProvider } from "./modalInvoice";
import { ModalPixContextProvider } from "./modalPix";
import { ModalTransferContextProvider } from "./modalTransfer";
import { AuthProvider } from './auth';
import { ClientProvider } from './client';
import { NotificationProvider } from "./notification";
import { BalanceProvider } from "./balance";

export const Hooks: React.FC = ({ children }) => {
  return (
    <>
    <ClientProvider>
      <AuthProvider>
        <BalanceProvider>
          <NotificationProvider>
            <ModalTransferContextProvider>
              <ModalInvoiceContextProvider>
                <ModalPixContextProvider>{children}</ModalPixContextProvider>
              </ModalInvoiceContextProvider>
            </ModalTransferContextProvider>
          </NotificationProvider>
        </BalanceProvider>
      </AuthProvider>
    </ClientProvider>
    </>
  );
};
