import React, { useEffect, useMemo, useState } from "react";

import { useNavigate } from "react-router-dom";

import { Button } from "../../components/button";
import SelectInput from "../../components/SelectInput";
import TextInput from "../../components/TextInput";
import { useNotification } from "../../hooks/notification";

import {
  ActionText,
  ButtonsWrapper,
  Container,
  Form,
  FormItem,
  FormWrapper,
} from "./styles";
import { ReturnBack } from "../../components/return-back";
import { api } from "../../services/api";

export interface IPixData {
  [x: string]: string;
  name: string
  bank: string
  bank_code: string
  bank_agency: string
  bank_account: string
  beneficiary_document: string
}


export function PixTransfer() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedKey, setSelectedKey] = useState("Tipo de chave");
  const [mask, setMask] = useState<string | undefined>(undefined);
  const [, setData] = useState<IPixData>({} as IPixData)

  const [key, setKey] = useState('');

  const { setOpenNotification } = useNotification();

  useEffect(() => {
    setKey('');
    if (selectedKey === 'CPF/CNPJ') {
      setMask('CPF_CNPJ');
    } else if (selectedKey === 'Celular') {
      setMask('(99) 99999-9999');
    } else {
      setMask(undefined);
    }
  }, [selectedKey]);

  async function handleNextButton() {
    if (selectedKey === 'Tipo de chave') {
      setOpenNotification({
        type: 'error',
        title: 'Tipo inválido',
        notification: 'Selecione uma tipo de chave Pix'
      });

      return;
    }

    if (key === '') {
      setOpenNotification({
        type: 'error',
        title: 'Chave inválida',
        notification: 'Digite uma chave Pix válida'
      });

      return;
    }

    setLoading(true);

    let pixKeyType = '';
    let formattedKey = key;

    if (selectedKey === "Celular") {
      pixKeyType = 'phone';
      formattedKey = key.replace(/\D/g, '');
    } else if (selectedKey === "CPF/CNPJ") {
      formattedKey = key.replace(/[^\d]/g, '');
      if (formattedKey.length === 11) {
        pixKeyType = 'cpf';
      } else if (formattedKey.length === 14) {
        pixKeyType = 'cnpj';
      } else {
        setOpenNotification({
          type: 'error',
          title: 'Chave inválida',
          notification: 'Digite um CPF ou CNPJ válido'
        });
        setLoading(false);
        return;
      }
    } else if (selectedKey === "E-mail") {
      pixKeyType = 'email';
    } else if (selectedKey === "Chave aleatória") {
      pixKeyType = 'evp';
    } else {
      setOpenNotification({
        type: 'error',
        title: 'Chave inválida',
        notification: 'Escolha um tipo de chave válido!'
      });
      setLoading(false);
      return;
    }


    try {
      const { data } = await api.post("/pix/pix-info", {
        pixKey: pixKeyType === 'phone' ?
        `+55${formattedKey.toLowerCase().trim()}` :
          formattedKey.toLowerCase().trim(),
        keyType: pixKeyType,
      });
      setData(data);

      let keySelected = formattedKey;

      if (selectedKey === 'CPF/CNPJ') {
        keySelected = formattedKey;
      } else if (selectedKey === 'Celular') {
        keySelected = `+55${formattedKey}`;
      }

      navigate("/pix/transfer/confirm", {
        state: { key: keySelected, keyType: selectedKey, pixData: data }
      });
    } catch (error) {
      console.log(error);
      setOpenNotification({
        type: 'error',
        title: 'Chave não encontrada',
        notification: 'Verifique a chave e tente novamente!'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCancelButton = () => {
    navigate("/pix");
  };

  const handleKeySelect = (option: string) => {
    setSelectedKey(option);
  };

  const TitleInput = useMemo(() => {
    switch (selectedKey) {
      case "Celular":
        return "Digite o celular"
      case "CPF/CNPJ":
        return "Digite o CPF/CNPJ"
      case "E-mail":
        return "Digite o E-mail"
      case "Chave aleatória":
        return "Digite a chave aleatória"
      default:
        return "Selecione a chave pix"
    }
  }, [selectedKey])

  return (
    <Container>
      <ReturnBack title="Voltar para a área PIX" />

      <Form>
        <FormWrapper>
          <FormItem>
            <h1 className="text-xl">Nova transferência</h1>
            <p className="mb-6 mt-2 text-tx-primary">
              Informe o tipo de chave e a chave Pix do destinatário:
            </p>
            <ActionText>Chave Pix</ActionText>
            <SelectInput
              placeholderColor={'#B5B7BD'}
              borderColor={'#E3E3E3'}
              optionSelected={selectedKey}
              handleFunction={handleKeySelect}
              options={["Celular", "CPF/CNPJ", "E-mail", "Chave aleatória"]}
            />
          </FormItem>
          <FormItem>
            <ActionText>
              {TitleInput}
            </ActionText>
            <TextInput
              mask={mask}
              borderColor="#E3E3E3" borderRadius="4px"
              value={key}
              onChange={input => setKey(input.target.value)}
            />
          </FormItem>
        </FormWrapper>
        <ButtonsWrapper>
          <Button
            color="#e3e3e3"
            title="Cancelar"
            handleFunction={handleCancelButton}
          />

          <Button type="button" isLoading={loading} handleFunction={handleNextButton} title="Continuar" />
        </ButtonsWrapper>
      </Form>
    </Container>
  );
};
