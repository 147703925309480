import { yupResolver } from '@hookform/resolvers/yup';
import { cnpj, cpf } from 'cpf-cnpj-validator';
import React, { FormEvent, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import TextInput from "../../components/TextInput";
import { Button } from "../../components/button";
import InputInsertValue from "../../components/input-value";

import { ExclamationIcon } from "@heroicons/react/solid";
import { useNotification } from "../../hooks/notification";
import { api, parseError } from "../../services/api";

import { ReturnBack } from '../../components/return-back';

type FormData = {
  name: string;
  cep: string;
  uf: string;
  date: string;
};

const schema = yup.object({
  name: yup.string().required("Nome é obrigatório"),
  cep: yup.string().required("CEP é obrigatório"),
  uf: yup.string().required("Estado é obrigatório"),
  date: yup.string().required("Vencimento é obrigatório"),
}).required();



export const DepositNewInvoice: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [document, setDocument] = useState('');
  const [value, setValue] = useState(0);
  const [maskedValue, setMaskedValue] = useState('');
  const [errorDocument, setErrorDocument] = useState('');
  const [error, setError] = useState('');

  const { setOpenNotification } = useNotification();

  const { register, handleSubmit, formState: { errors } } = useForm<FormData>({
    resolver: yupResolver(schema)
  });

  const handleCancel = () => {
    navigate("/deposit");
  };

  const handleChange = (event: FormEvent, value: number, maskedValue: string) => {
    event.preventDefault();

    setValue(value);
    setMaskedValue(maskedValue);
  };

  const handleConfirm: SubmitHandler<FormData> = async (values, event) => {
    setErrorDocument('');
    setLoading(false);
    if (!document || document === null || document === '') {
      setErrorDocument("Documento é obrigatório");
      return;
    }

    if (!cpf.isValid(document) && !cnpj.isValid(document)) {
      setErrorDocument("Documento inválido");
      return;
    }

    if (value <= 0) {
      setOpenNotification({
        type: 'warning',
        title: 'Valor obrigatório',
        notification: "É preciso informar um valor"
      });

      return;
    }

    setLoading(true);
    setError("");

    try {
      const { data } = await api.post("/billets", {
        amount: value,
        dueDate: values.date,
        customer: {
          document: document.replace(/[/,.,-\s]/g, ""),
          name: values.name,
          zipCode: values.cep.replace(/[-,\s]/g, ""),
          state: values.uf,
        }
      });
      setLoading(false);
      navigate("confirm", {
        state: { ...data, maskedValue },
      });

    } catch (err) {
      const error = parseError(err);
      setError(error.friend);
      setLoading(false);
    }
  };

  return (
    <div className='h-screen p-8 mb-32 bg-[var(--background)]'>
      <ReturnBack title='Voltar para Depositar' />

      <form onSubmit={handleSubmit(handleConfirm)}>
        <div className='max-w-[480px] bg-white p-8 flex flex-col rounded shadow'>
          <div className="mb-5">
            <h1 className="text-xl">Gerar nova cobrança</h1>
            <p className="mb-6 mt-2 text-tx-primary">
              Informe os dados para emitir uma nova cobrança:
            </p>
            <p className='font-[var(--semiBold)] text-dark-gray mb-4'>Qual o nome completo de quem você vai emitir o boleto?</p>
            <TextInput
              borderColor="#E3E3E3"
              placeholder=""
              type="text"
              {...register("name")}
              error={errors.name}
            />
          </div>

          <div className="mb-5">
            <p className='font-[var(--semiBold)] text-dark-gray mb-4'>Qual o CPF/CNPJ de quem você vai emitir o boleto?</p>
            <TextInput
              mask="CPF_CNPJ"
              borderColor="#E3E3E3"
              placeholder=""
              type="text"
              value={document}
              onChange={(e) => setDocument(e.target.value)}
              error={!!errorDocument ? { message: errorDocument, type: "required", ref: this } : undefined}
            />
          </div>
          <div className="mb-5 flex justify-between">
            <div className="w-full mr-5">
              <p className='font-[var(--semiBold)] text-dark-gray mb-4'>Informe o CEP</p>
              <TextInput
                mask="99999-999"
                borderColor="#E3E3E3"
                placeholder=""
                {...register("cep")}
                error={errors.cep} />
            </div>
            <div className="w-1/2">
              <p className='font-[var(--semiBold)] text-dark-gray mb-4'>Informe o estado</p>
              <TextInput
                borderColor="#E3E3E3"
                placeholder=""
                type="text"
                maxLength={2}
                {...register("uf")}
                error={errors.uf}
              />
            </div>
          </div>

          <div className="mb-5">
            <p className='font-[var(--semiBold)] text-dark-gray mb-4'>Qual o vencimento dessa cobrança?</p>
            <TextInput
              borderColor="#E3E3E3"
              placeholder=""
              type="date"
              {...register("date")}
              error={errors.date} />
          </div>

          <InputInsertValue onChange={(event, value, masked) => handleChange(event, value, masked)} />

          {!!error && (
            <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-5">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-yellow-700">
                    {error}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="flex items-center gap-6 mt-6 max-w-[480px]">
          <Button handleFunction={handleCancel} color="#e3e3e3" title="Cancelar" />
          <Button type="submit" isLoading={loading} title="Continuar" />
        </div>
      </form>
    </div>
  );
};
