import React from "react";
import { CardActionOption } from "../../components/cardActionOption";

import {
  Container,
  Content,
  OptionsWrapper,
  // PixHelpMeIcon,
  PixIcon,
  // PixMyKeysIcon,
} from "./styles";

export const PixOptions = () => {
  return (
    <Container>
      <Content>
        <OptionsWrapper>
          <CardActionOption linkTo="/pix/transfer" Icon={PixIcon} title={`Transferir`} />
          {/* <CardActionOption
            linkTo="mykeys"
            Icon={PixMyKeysIcon}
            title={"Minhas chaves"}
          /> */}
          {/* <CardActionOption Icon={PixHelpMeIcon} title={"Me ajuda"} /> */}
          <CardActionOption linkTo="/pix/transfer/transfer-contacts" Icon={PixIcon} title={`Transferir para meus contatos`} />
        </OptionsWrapper>
      </Content>
    </Container>
  );
};
