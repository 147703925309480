const colors = {
  background: '#F7F6F8',
  'gray-light-line': '#E3E3E3',
  "gray-light-text": '#A3A3A3',
  "gray-light-order": '#B5B7BD',
  "cian-dark": 'rgba(0, 0, 0, .3)',
  "gray": '#142249',
  "gray-light-05": 'rgba(20, 34, 73, .05)',
  "gray-background": '#0E1833',
  "gray-dark": '#282A3A',
  "cian": '#38D2D9',
  "white": '#fff',
  "white-light": 'rgba(255, 255, 255, .3)',
  "white-light-5": 'rgba(255, 255, 255, .05)',
  "green": '#49D294',
  "red": '#E74C3C',
  "line": 'rgba(255, 255, 255, .1)',
  "modalTitle": '#777986',
  "yellow": '#F6BC4D',
  "confirmBackground": '#EAEAEA',
  "borderInput": '#3B4C7E',
  "lineBorder": '#707070',
  "box": '#F2F2F2',
  "boxText": '#999999',
};
const setup = [
  {
    name: 'NobePag',
    host: "nobepag.app.br",
    client_id: "e1c81c9f-b51a-4dcb-ad89-bb9af4761965",
    colors: {
      ...colors,
    },
    whiteLogo: {
      "image": require('../assets/clients/nobe/logo/white.png'),
      "width": 225 * 0.4,
      "height": 116 * 0.4,
      "maxWidth": 225 * 0.8,
      "maxHeight": 116 * 0.8,
      "objectFit": "contain"
    },
    darkLogo: {
      "image": require('../assets/clients/nobe/logo/dark.png'),
      "width": 217 * 0.4,
      "height": 120 * 0.4,
      "maxWidth": 261 * 0.8,
      "maxHeight": 120 * 0.8,
      "objectFit": "contain"
    },
  },
  {
    name: 'NobePag',
    host: "novo.nobebank.com.br",
    client_id: "e1c81c9f-b51a-4dcb-ad89-bb9af4761965",
    colors: {
      ...colors,
    },
    whiteLogo: {
      "image": require('../assets/clients/nobe/logo/white.png'),
      "width": 225 * 0.4,
      "height": 116 * 0.4,
      "maxWidth": 225 * 0.8,
      "maxHeight": 116 * 0.8,
      "objectFit": "contain"
    },
    darkLogo: {
      "image": require('../assets/clients/nobe/logo/dark.png'),
      "width": 217 * 0.4,
      "height": 120 * 0.4,
      "maxWidth": 261 * 0.8,
      "maxHeight": 120 * 0.8,
      "objectFit": "contain"
    },
  },
  {
    name: 'Icard Pay',
    host: "icard.stric.com.br",
    client_id: "a9d32ac4-de8d-4f66-9c21-d1878adafa8d",
    // client_id: "e1c81c9f-b51a-4dcb-ad89-bb9af4761965",
    colors: {
      ...colors,
      "gray": '#151518',
      "cian": '#00A7D2',
      "yellow": '#F6BC4D',
      "green": '#00A7D2',
    },
    whiteLogo: {
      "image": require('../assets/clients/icard/logo/white.png'),
      "width": 100,
      "height": 62.62,
      "maxWidth": 130,
      "maxHeight": 81.4,
      "objectFit": "contain"
    },
    darkLogo: {
      "image": require('../assets/clients/icard/logo/dark.png'),
      "width": 100,
      "height": 62.62,
      "maxWidth": 130,
      "maxHeight": 81.4,
      "objectFit": "contain"
    },
  },
  {
    name: 'VF Bank Digital',
    host: "vfbankdigital.com.br",
    client_id: "a9d32ac4-de8d-4f66-9c21-d1878adafa8d",
    // client_id: "e1c81c9f-b51a-4dcb-ad89-bb9af4761965",
    colors: {
      ...colors,
      "gray": '#151518',
      "cian": '#e2af0d',
      "yellow": '#F6BC4D',
      "green": '#e2af0d',
    },
    whiteLogo: {
      "image": require('../assets/clients/vfbank/logo/white.png'),
      "width": 93.3,
      "height": 68.6,
      "maxWidth": 124.4,
      "maxHeight": 91.46,
      "objectFit": "contain"
    },
    darkLogo: {
      "image": require('../assets/clients/vfbank/logo/dark.png'),
      "width": 108,
      "height": 108,
      "maxWidth": 162,
      "maxHeight": 162,
      "objectFit": "contain"
    },
  },
  {
    name: 'Cone Banx',
    host: "conebanx.com.br",
    client_id: "ecd982cc-f3b8-4331-a072-88f6366fa41b",
    // client_id: "a9d32ac4-de8d-4f66-9c21-d1878adafa8d",
    colors: {
      ...colors,
      // "gray": '#696a6e',
      "gray": '#838488',
      // "cian": '#e2af0d',
      // "yellow": '#F6BC4D',
      // "green": '#e2af0d',
    },
    whiteLogo: {
      "image": require('../assets/clients/conebanx/logo/white.png'),
      "width": 145.8,
      "height": 41.8,
      "maxWidth": 218.7,
      "maxHeight": 62.7,
      "objectFit": "contain"
    },
    darkLogo: {
      "image": require('../assets/clients/conebanx/logo/dark.png'),
      "width": 145.8,
      "height": 41.8,
      "maxWidth": 218.7,
      "maxHeight": 62.7,
      "objectFit": "contain"
    },
  },
  {
    name: 'Theos Bank',
    host: "theosbank.stric.com.br",
    client_id: "a9d32ac4-de8d-4f66-9c21-d1878adafa8d",
    // client_id: "e1c81c9f-b51a-4dcb-ad89-bb9af4761965",
    colors: {
      ...colors,
      "gray": '#382f2d',
      "cian": '#e31c79',
      "yellow": '#d3bba8',
      "green": '#6d4f47',
      "borderInput": '#d3bba8',
    },
    whiteLogo: {
      "image": require('../assets/clients/theosbank/logo/white.png'),
      "width": 157.59,
      "height": 27.99,
      "maxWidth": 262.65,
      "maxHeight": 46.65,
      "objectFit": "contain"
    },
    darkLogo: {
      "image": require('../assets/clients/theosbank/logo/dark.png'),
      "width": 263.4,
      "height": 47.45,
      "maxWidth": 263.4,
      "maxHeight": 47.45,
      "objectFit": "contain"
    },
  },
  {
    name: 'Rebanx',
    host: "rebanx.stric.com.br",
    // client_id: "d2afc12c-bc27-440c-96c2-88e006c92ab2",
    client_id: "e1c81c9f-b51a-4dcb-ad89-bb9af4761965",
    colors: {
      ...colors,
    },
    whiteLogo: {
      "image": require('../assets/clients/rebanx/logo/white.png'),
      "width": 149.5,
      "height": 44.4,
      "maxWidth": 224.25,
      "maxHeight": 66.6,
      "objectFit": "contain"
    },
    darkLogo: {
      "image": require('../assets/clients/rebanx/logo/dark.png'),
      "width": 154.4,
      "height": 44.1,
      "maxWidth": 231.6,
      "maxHeight": 66.15,
      "objectFit": "contain"
    },
  },
  {
    name: 'Klaro',
    host: "klaro.stric.com.br",
    client_id: "d2afc12c-bc27-440c-96c2-88e006c92ab2",
    colors: {
      ...colors,
    },
    whiteLogo: {
      "image": require('../assets/clients/klaro/logo/white.png'),
      "width": 92.49,
      "height": 47.22,
      "maxWidth": 154.15,
      "maxHeight": 78.7,
      "objectFit": "contain"
    },
    darkLogo: {
      "image": require('../assets/clients/klaro/logo/dark.png'),
      "width": 92.49,
      "height": 47.22,
      "maxWidth": 154.15,
      "maxHeight": 78.7,
      "objectFit": "contain"
    },
  },
  {
    name: 'Imobi Places',
    host: "imobi.stric.com.br",
    client_id: "d2afc12c-bc27-440c-96c2-88e006c92ab2",
    colors: {
      ...colors,
      gray: '#0733c0',
    },
    whiteLogo: {
      "image": require('../assets/clients/imobi/logo/white.png'),
      "width": 168.92,
      "height": 31.8,
      "maxWidth": 168.92,
      "maxHeight": 31.8,
      "objectFit": "contain"
    },
    darkLogo: {
      "image": require('../assets/clients/imobi/logo/dark.png'),
      "width": 123,
      "height": 41.5,
      "maxWidth": 123,
      "maxHeight": 41.5,
      "objectFit": "contain"
    },
  },
  {
    name: 'Stric',
    host: "bank.stric.com.br",
    client_id: "d2afc12c-bc27-440c-96c2-88e006c92ab2",
    colors: {
      ...colors,
    },
    whiteLogo: {
      "image": require('../assets/clients/stric/logo/white.png'),
      "width": 58,
      "height": 26,
      "maxWidth": 58,
      "maxHeight": 26,
      "objectFit": "contain"
    },
    darkLogo: {
      "image": require('../assets/clients/stric/logo/dark.png'),
      "width": 63,
      "height": 24,
      "maxWidth": 63,
      "maxHeight": 24,
      "objectFit": "contain"
    },
  },
  {
    name: 'Ajio Pay',
    host: "ib.ajiopay.com.br",
    client_id: "ecd982cc-f3b8-4331-a072-88f6366fa41b",
    colors: {
      ...colors,
    },
    whiteLogo: {
      "image": require('../assets/clients/ajiopay/logo/white.png'),
      "width": 50,
      "height": 50,
      "maxWidth": 90,
      "maxHeight": 90,
      "objectFit": "contain"
    },
    darkLogo: {
      "image": require('../assets/clients/ajiopay/logo/white.png'),
      "width": 70,
      "height": 70,
      "maxWidth": 90,
      "maxHeight": 90,
      "objectFit": "contain"
    },
  },
  {
    name: 'Ajio Pay',
    host: "pedzap.ajiopay.com.br",
    client_id: "c53bc6ad-7450-4774-9ee6-da1898936791",
    colors: {
      ...colors,
    },
    whiteLogo: {
      "image": require('../assets/clients/ajiopay/logo/white.png'),
      "width": 50,
      "height": 50,
      "maxWidth": 90,
      "maxHeight": 90,
      "objectFit": "contain"
    },
    darkLogo: {
      "image": require('../assets/clients/ajiopay/logo/white.png'),
      "width": 70,
      "height": 70,
      "maxWidth": 90,
      "maxHeight": 90,
      "objectFit": "contain"
    },
  },
  {
    name: 'NM Pay',
    host: "nmpay.stric.com.br",
    client_id: "d2afc12c-bc27-440c-96c2-88e006c92ab2",
    colors: {
      ...colors,
    },
    whiteLogo: {
      image: require('../assets/clients/nmpay/logo/white.png'),
      width: 39,
      height: 51.6,
      maxWidth: 78,
      maxHeight: 103.2,
      "objectFit": "contain"
    },
    darkLogo: {
      image: require('../assets/clients/nmpay/logo/dark.png'),
      width: 39,
      height: 51.6,
      maxWidth: 105.6,
      maxHeight: 139.2,
      "objectFit": "contain"
    },
  },
  {
    name: 'Aviv Saúde',
    host: "aviv.stric.com.br",
    client_id: "e1c81c9f-b51a-4dcb-ad89-bb9af4761965",
    colors: {
      ...colors,
      gray: "#001489",
      // cian: "#FFC107",
      // "gray-background": '#0E1833',
    },
    whiteLogo: {
      "image": require('../assets/clients/aviv/logo/white.png'),
      "width": 106.75,
      "height": 44.1,
      "maxWidth": 213.5,
      "maxHeight": 88.2,
      "objectFit": "contain"
    },
    darkLogo: {
      "image": require('../assets/clients/aviv/logo/dark.png'),
      "width": 118.2,
      "height": 59.75,
      "maxWidth": 236.4,
      "maxHeight": 119.5,
      "objectFit": "contain"
    },
  }
];


export default setup;
