import { Link } from 'react-router-dom'

import { Button } from './button'
import { ReturnBack } from './return-back'

interface Props {
  to: string
  title: string
  returnTitle: string
  action?: () => void
}

export function NavButtons({ to, returnTitle, title, action }: Props) {
  return (
    <div className="mb-6 flex flex-col items-stretch">
      <div className="flex justify-between">
        <ReturnBack title={returnTitle} />
        <Link to={to} className="max-w-[240px] mt-16">
          <Button title={title} onClick={action} />
        </Link>
      </div>
    </div>

  )
}
