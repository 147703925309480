import React from 'react'
import { CardActionOption } from '../../components/cardActionOption'
import { useClient } from '../../hooks/client'

import {
  Container,
  Content,
  OptionsWrapper,
  SentIcon,
} from './styles'

import {
  ArrowRightIcon,
  Container as OptionContainer,
  IconWrapper,
} from '../../components/cardActionOption/styles'

export const Transfer: React.FC = () => {
  const { client } = useClient()

  return (
    <Container>
      <Content>
        <OptionsWrapper>
          {/* <OptionContainer to={'list'}>
            <IconWrapper>
              <img
                src={client.darkLogo.image}
                alt={client.name}
                style={{
                  width: client.whiteLogo.width + 24,
                  height: client.whiteLogo.height,
                }}
              />
            </IconWrapper>
            <div>
              <span>{`Transferir para ${client.name}`}</span>
              <ArrowRightIcon />
            </div>
          </OptionContainer> */}
          <CardActionOption
            Icon={SentIcon}
            linkTo="subscribe"
            title={'Transferir para outros bancos'}
          />
          <CardActionOption
            Icon={SentIcon}
            linkTo="subscribe-contacts"
            title={'Transferir para meus contatos'}
          />
        </OptionsWrapper>
      </Content>
    </Container>
  )
}


