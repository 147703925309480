import { useNavigate } from "react-router-dom";
import { ChevronLeft } from "lucide-react";

import { useAuth } from "../../hooks/auth";
import { useClient } from "../../hooks/client";
import { documentFormatted } from "../../utils/documentFormatted";
import {
  Container,
  Content,
  BoxInfo,
  Name,
  Info,
} from "./styles";

export const DepositInfoTedDoc = () => {
  const { account } = useAuth();
  const { client } = useClient();
  const navigate = useNavigate()

  return (
    <Container>
      <Content>
        <div className="mb-6 flex items-center">
          <div
            onClick={() => navigate(-1)}
            className="mr-4 flex h-10 w-10 cursor-pointer items-center justify-center rounded bg-white shadow-sm hover:opacity-80"
          >
            <ChevronLeft className="h-7 w-7 text-primary" strokeWidth={2.2} />
          </div>
          <h1 className="mt-1 text-2xl text-tx-primary">
            Depositar
          </h1>
        </div>
        <BoxInfo>
          <h1 className="text-xl">Dados para transferência</h1>
          <p className="mb-6 mt-2 text-tx-primary">
            Use os dados abaixo para fazer um TED ou DOC para sua conta {client.name}:
          </p>
          <Name>
            {account.name.toUpperCase()}
          </Name>
          <Info>
            <strong>CNPJ: </strong>
            <span>{documentFormatted(account.document)}</span>
          </Info>
          {!account.isPool && (
            <>
              <Info>
                <strong>Agência: </strong>
                <span>{account.branch}</span>
              </Info>
            </>
          )}
          <Info>
            <strong>Conta: </strong>
            <span>{account.account}-{account.accountDigit}</span>
          </Info>
        </BoxInfo>
      </Content>
    </Container>
  );
};
