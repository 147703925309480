import React, { createContext, useCallback, useState, useContext, useEffect } from 'react';

import { api, CommonHeaderProperties } from '../services/api';
import { useClient } from './client';

interface Account {
  id: string;
  name: string;
  document: string;
  balance: number;
  bank: string;
  branch: string;
  account: string;
  accountDigit: string;
  status: string;
  type: string;
  pin: boolean;
  email: string;
  isPool: boolean;
}

interface AuthState {
  token: string;
  account: Account;
}

interface SignInCredentials {
  document: string;
  password: string;
}

interface AuthContextData {
  account: Account;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  refresh: () => void;
  refreshAccount: () => void;
  // updateBalance(balance: number): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

interface AuthProviderProps {
  children: React.ReactNode;
}


export function AuthProvider({ children }: AuthProviderProps) {
  const { client } = useClient();
  const [data, setData] = useState<AuthState>(() => {
    const token = sessionStorage.getItem('@stricv2:token');
    const account = sessionStorage.getItem('@stricv2:account') as string;


    if (token && account) {
      const accountData = JSON.parse(account);

      api.defaults.headers = {
        Authorization: `Bearer ${token}`,
        account: accountData.id,
      } as CommonHeaderProperties;

      return { token, account: accountData };
    }

    return {} as AuthState;
  });

  async function checkStep(data: Account, token: string) {
    const type = data.type === 'individuals' ? 'pf' : 'pj';
    if (data.status === 'WAITING_ADDRESS') {
      window.localStorage.setItem('@stricv2:status', 'WAITING_ADDRESS');
      window.localStorage.setItem('@stricv2:id', token);
      localStorage.removeItem('@stricv2:token');
      localStorage.removeItem('@stricv2:account');
      window.location.href = `/create-account/?type=${type}`;
      return;
    }

    if (data.status === 'WAITING_DOCUMENTS') {
      window.localStorage.setItem('@stricv2:status', 'WAITING_DOCUMENTS');
      window.localStorage.setItem('@stricv2:id', token);
      localStorage.removeItem('@stricv2:token');
      localStorage.removeItem('@stricv2:account');
      sessionStorage.removeItem('@stricv2:token');
      sessionStorage.removeItem('@stricv2:account');
      window.location.href = `/create-account/?type=${type}`;
      return;
    }

    if (data.status === 'WAITING_ANALYSIS') {
      window.localStorage.setItem('@stricv2:status', 'WAITING_ANALYSIS');
      window.localStorage.setItem('@stricv2:id', token);
      localStorage.removeItem('@stricv2:token');
      localStorage.removeItem('@stricv2:account');
      sessionStorage.removeItem('@stricv2:token');
      sessionStorage.removeItem('@stricv2:account');
      window.location.href = `/create-account/?type=${type}`;
      return;
    }

    if (data.status === 'UNDER_ANALYSIS') {
      window.localStorage.setItem('@stricv2:status', 'UNDER_ANALYSIS');
      localStorage.removeItem('@stricv2:token');
      localStorage.removeItem('@stricv2:account');
      localStorage.removeItem('@stricv2:token');
      localStorage.removeItem('@stricv2:account');
      sessionStorage.removeItem('@stricv2:token');
      sessionStorage.removeItem('@stricv2:account');
      window.location.href = `/create-account/?type=${type}`;
      return;
    }

    return true;
  }

  async function getAccount() {
    const token = window.localStorage.getItem('@stricv2:token') ||
    window.sessionStorage.getItem('@stricv2:token');
    if (token) {
      try {
        api.defaults.headers = {
          Authorization: `Bearer ${token}`,
        } as CommonHeaderProperties;

        const { data } = await api.get('/accounts');

        await checkStep(data, token);

        setData({ token, account: data });
      } catch(err) {
        signOut();
      }
    }
  }

  function refresh() {
    getAccount();

    setTimeout(() => {
      getAccount();
    }, 2000);

    setTimeout(() => {
      getAccount();
    }, 10000);

    setTimeout(() => {
      getAccount();
    }, 60000);
  }

  function refreshAccount() {
    setInterval(() => {
      getAccount();
    }, 60000);
  }


  useEffect(() => {
    getAccount();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signIn = useCallback(async ({ document, password }) => {
    window.localStorage.removeItem('@stricv2:status');
    window.localStorage.removeItem('@stricv2:id');

    const response = await api.post('/accounts/session', {
      document,
      password,
    }, {
      headers: {
        client: client.client_id, // "d2afc12c-bc27-440c-96c2-88e006c92ab2"
      }
    });


    const { access_token: token } = response.data;

    api.defaults.headers = {
      Authorization: `Bearer ${token}`,
    } as CommonHeaderProperties;

    const { data } = await api.get("/accounts");

    sessionStorage.setItem('@stricv2:token', token);
    sessionStorage.setItem('@stricv2:account', JSON.stringify(data));

    api.defaults.headers = {
      Authorization: `Bearer ${token}`,
      account: data.id,
    } as CommonHeaderProperties;

    await checkStep(data, token);

    setData({ token, account: data });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@stricv2:token');
    localStorage.removeItem('@stricv2:account');
    sessionStorage.removeItem('@stricv2:token');
    sessionStorage.removeItem('@stricv2:account');

    setData({} as AuthState);
  }, []);


  return (
    <AuthContext.Provider
      value={{ account: data.account, signIn, signOut, refresh, refreshAccount }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}



